import * as React from "react";

function IconEye() {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4c2.727 0 5.335 1.43 7.766 3.781a23.31 23.31 0 012.24 2.515c.398.516.681.923.836 1.165l.345.539-.345.539c-.155.242-.438.649-.836 1.165-.66.857-1.411 1.713-2.24 2.515C17.336 18.569 14.727 20 12 20c-2.728 0-5.336-1.43-7.767-3.781a23.313 23.313 0 01-2.24-2.515 17.482 17.482 0 01-.836-1.165L.812 12l.345-.539c.155-.242.438-.649.836-1.165.66-.857 1.411-1.713 2.24-2.515C6.663 5.431 9.272 4 12 4zm8.422 7.517a21.33 21.33 0 00-2.047-2.298C16.282 7.194 14.105 6 12 6 9.894 6 7.717 7.194 5.624 9.219A21.323 21.323 0 003.216 12a21.318 21.318 0 002.408 2.781C7.717 16.806 9.894 18 11.999 18c2.106 0 4.283-1.194 6.376-3.219A21.325 21.325 0 0020.784 12c-.11-.152-.23-.314-.361-.483zM8 12a4 4 0 108 0 4 4 0 00-8 0zm6 0a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>
  );
}

export default IconEye;

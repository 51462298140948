import PropTypes from "prop-types";
import cn from "classnames";
import styles from "solar/components/FormNote.module.scss";

export default function FormNote(props) {
  const { hasError, children, className } = props;

  return (
    <div className={cn(styles.component, hasError && styles.error, className)}>
      {children}
    </div>
  );
}

FormNote.propTypes = {
  children: PropTypes.node.isRequired,
  hasError: PropTypes.bool,
  className: PropTypes.node,
};

FormNote.defaultProps = {
  hasError: false,
  className: null,
};

import PropTypes from "prop-types";
import cn from "classnames";
import styles from "solar/components/Container.module.scss";

export default function Container({
  children,
  fluid = false,
  wide = false,
  className = null,
}) {
  return (
    <div
      className={cn(
        styles.component,
        wide && styles.wide,
        fluid && styles.fluid,
        className
      )}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  fluid: PropTypes.bool,
  className: PropTypes.node,
};

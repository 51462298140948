import * as React from "react";

function IconChatQuestion() {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22a9.959 9.959 0 004.86-1.258l4.235.353-.353-4.235A9.959 9.959 0 0022 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10zm4.115-3.138l.277-.166 2.513.21-.21-2.514.167-.277a8 8 0 10-2.747 2.747zM12 16.998a1 1 0 100-2 1 1 0 000 2zM11 14h2v-1c0 .002.005-.003.015-.015.027-.028.089-.097.205-.19.1-.079.132-.1.385-.26A3 3 0 109 10h2a1 1 0 111.534.846c-.324.205-.378.24-.557.383C11.383 11.7 11 12.263 11 13v1z"
      />
    </svg>
  );
}

export default IconChatQuestion;

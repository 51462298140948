import PropTypes from "prop-types";
import cn from "classnames";
import styles from "components/shared/stays/Title.module.scss";

export default function Title({
  children,
  size = 4,
  tag = null,
  font = "lettera-text",
  className = null,
}) {
  const mapStyles = {
    1: styles[`title-1`],
    2: styles[`title-2`],
    3: styles[`title-3`],
    4: styles[`title-4`],
    5: styles[`title-5`],
  };

  const mapTag = {
    1: "h1",
    2: "h2",
    3: "h3",
    4: "h4",
    5: "h5",
  };

  const Tag = tag || mapTag[size] || "div";

  return (
    <Tag
      className={cn(
        styles.component,
        font === "lettera-text" ? styles["lettera-text"] : styles.cushing,
        mapStyles[size],
        className
      )}
    >
      {children}
    </Tag>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.node,
};

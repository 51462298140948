import PropTypes from "prop-types";
import Link from "next/link";
import ChatButton from "components/shared/stays/ChatButton";
import styles from "components/shared/stays/FooterLink.module.scss";

export default function FooterLink({
  text,
  href = null,
  variant = "external",
}) {
  return (
    <li className={styles.component}>
      {variant === "external" && (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {text}
        </a>
      )}
      {variant === "internal" && (
        <Link href={href} prefetch={false} className={styles.link}>
          {text}
        </Link>
      )}
      {variant === "intercom" && (
        <ChatButton text={text} type="text-only" className={styles.link} />
      )}
    </li>
  );
}

FooterLink.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  variant: PropTypes.string,
};

import * as React from "react";

function IconFacebookMessenger() {
  return (
    <svg width={23} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prefix__clip0_1007_21)">
        <path
          d="M11.4 0C5.1 0 0 4.86 0 10.8c0 3.12 1.38 5.88 3.6 7.86V24l4.68-2.82c.96.24 2.04.42 3.12.42 6.3 0 11.4-4.86 11.4-10.8S17.7 0 11.4 0z"
          fill="#448AFF"
        />
        <path d="M4.2 14.4l6-6.6 3 3 5.4-3-6 6.6-3-3-5.4 3z" fill="#fff" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1007_21">
          <path fill="#fff" d="M0 0h22.8v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconFacebookMessenger;

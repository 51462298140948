import * as React from "react";

function IconTwitterColor() {
  return (
    <svg width={24} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prefix__clip0_1007_28)">
        <path
          d="M24 2.819a9.716 9.716 0 01-2.755.754A4.787 4.787 0 0023.354.94a9.618 9.618 0 01-3.044 1.152A4.785 4.785 0 0016.817.595c-2.647 0-4.792 2.126-4.792 4.748 0 .37.043.732.125 1.083A13.654 13.654 0 012.275 1.46a4.738 4.738 0 001.482 6.345 4.878 4.878 0 01-2.172-.593v.056c0 2.304 1.652 4.222 3.842 4.658-.4.11-.823.17-1.259.17-.31 0-.611-.034-.903-.087a4.8 4.8 0 004.474 3.3 9.681 9.681 0 01-7.093 1.965 13.693 13.693 0 007.346 2.13c8.812 0 13.632-7.236 13.632-13.514 0-.205-.006-.41-.016-.613A9.425 9.425 0 0024 2.818z"
          fill="#03A9F4"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1007_28">
          <path
            fill="#fff"
            transform="translate(0 .595)"
            d="M0 0h24v18.811H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconTwitterColor;

import { useRef, useState } from "react";
import useDetectOutsideClick from "helpers/hooks/useDetectOutsideClick";
import cn from "classnames";
import useUserAuth from "helpers/hooks/useUserAuth";
import IconAvatar from "solar/icons/IconAvatar";
import NavbarBigMenuItem from "components/shared/stays/NavbarBigMenuItem";
import styles from "components/shared/stays/NavbarBigMenu.module.scss";

export default function App() {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  useDetectOutsideClick(buttonRef, dropdownRef, () => setIsActive(false));

  const onClick = () => {
    setIsActive(!isActive);
  };

  const authenticated = useUserAuth();

  return (
    <div className={styles.component}>
      <button type="button" onClick={onClick} ref={buttonRef}>
        <IconAvatar />
      </button>
      <div
        ref={dropdownRef}
        className={cn(styles.menu, isActive ? styles.active : styles.inactive)}
      >
        {authenticated ? (
          <>
            <NavbarBigMenuItem
              text="Messages"
              href="/traveler/inbox"
              onClick={onClick}
              highlight
            />
            <NavbarBigMenuItem
              text="Reservations"
              href="/reservations/stays"
              onClick={onClick}
              highlight
            />
            <NavbarBigMenuItem
              text="Wishlists"
              href="/wishlists"
              onClick={onClick}
              highlight
            />
            <div className={styles.divider} />
            <NavbarBigMenuItem
              text="Account"
              href="/account"
              onClick={onClick}
            />
            <NavbarBigMenuItem
              text="Help"
              href="https://www.notion.so/withthermal/Help-center-d728e23d75f94c5e9a4f6d920476a34b"
              onClick={onClick}
              external
            />
            <NavbarBigMenuItem
              text="Log out"
              href="/logout"
              onClick={onClick}
            />
          </>
        ) : (
          <>
            <NavbarBigMenuItem
              text="Log in"
              href="/login"
              onClick={onClick}
              highlight
            />
            <NavbarBigMenuItem
              text="Sign up"
              href="/signup"
              onClick={onClick}
            />
            <div>
              <NavbarBigMenuItem
                text="Help"
                href="https://www.notion.so/withthermal/Help-center-d728e23d75f94c5e9a4f6d920476a34b"
                onClick={onClick}
                external
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

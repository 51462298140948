import * as React from "react";

function IconChevronLeft() {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.414 12l7.293 7.293-1.414 1.414L6.586 12l8.707-8.707 1.414 1.414L9.414 12z"
      />
    </svg>
  );
}

export default IconChevronLeft;

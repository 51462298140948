import PropTypes from "prop-types";
import styles from "components/auth/FormSubtext.module.scss";

export default function FormSubtext({ children }) {
  return <div className={styles.component}>{children}</div>;
}

FormSubtext.propTypes = {
  children: PropTypes.node.isRequired,
};

import * as React from "react";

function IconChatText() {
  return (
    <svg
      width={25}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4 22.785l6.695-4.185H20.8a2.2 2.2 0 002.2-2.2V3.2A2.2 2.2 0 0020.8 1H3.2A2.2 2.2 0 001 3.2v13.2a2.2 2.2 0 002.2 2.2h2.2v4.185zm6.064-6.385L7.6 18.815V16.4H3.2V3.2h17.6v13.2h-9.336zM6.5 13.1v-2.2h7.7v2.2H6.5zm0-6.6v2.2h9.9V6.5H6.5z"
        fill="#000"
      />
    </svg>
  );
}

export default IconChatText;

import { useState, useEffect } from "react";
import { useAuthContext } from "context/auth-context";

export default function useUserAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const user = useAuthContext().getUserData();

  useEffect(() => {
    setAuthenticated(!!user.email);
  }, [user]);
  
  return authenticated;
}

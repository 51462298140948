import PropTypes from "prop-types";
import cn from "classnames";
import styles from "solar/components/TextInput.module.scss";

export default function TextInput({
  name,
  type,
  // register = null,
  register = () => {},
  defaultValue = "",
  isDisabled = false,
  isReadOnly = false,
  isRequired = false,
  pattern = null,
  maxLength = 524288,
  hasError = false,
  placeholder = "",
  onChange = null,
  onClick = null,
  onFocus = null,
  onBlur = null,
  onKeyDown = null,
  onKeyUp = null,
  onKeyPress = null,
  autoComplete = "",
  block = false,
  className = null,
}) {
  return (
    <input
      id={name}
      name={name}
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(name, {
        onBlur,
        onChange,
      })}
      defaultValue={defaultValue}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      pattern={pattern}
      placeholder={placeholder}
      required={isRequired}
      autoComplete={autoComplete}
      maxLength={maxLength}
      className={cn(
        styles.component,
        block && styles.block,
        isReadOnly && styles["read-only"],
        hasError && styles.error,
        isDisabled && styles.disabled,
        className
      )}
    />
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.func,
  defaultValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  pattern: PropTypes.func,
  maxLength: PropTypes.number,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoComplete: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.node,
};

import {
  format,
  parse,
  parseISO,
  minutesToHours,
  intervalToDuration,
} from "date-fns";
import pluralize from "pluralize";
import { maxPricePerTraveler } from "helpers/filters";

function dateFormatter(date, formatStr) {
  try {
    return format(parseISO(date), formatStr);
  } catch (error) {
    return date;
  }
}

export function formatDate(date) {
  return dateFormatter(date, "MMMM d, yyyy");
}

export function formatDateToMonthYear(date) {
  return dateFormatter(date, "MMMM yyyy");
}

export function formatDateToShortMonthYear(date) {
  return dateFormatter(date, "MMM yyyy");
}

export function formatDateToMonthDay(date) {
  return dateFormatter(date, "MMM d");
}

export function formatDateToMonthDayYear(date) {
  return dateFormatter(date, "MMM d, yyyy");
}

export function formatDateToYear(time) {
  return dateFormatter(time, "yyyy");
}

export function formatDateToTime(date) {
  return dateFormatter(date, "h:mma");
}

export function formatDateTime(time) {
  return dateFormatter(time, "MM/dd/yyyy h:mm:ss a");
}

export function formatTwelveHrTime(time) {
  const today = new Date();
  return new Date(
    `${today.getMonth()} ${today.getDate()}, ${today.getFullYear()} ${time}`
  ).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export function formatDateToSlash(date) {
  return dateFormatter(date, "M/dd/yy");
}

export function dateOrDatesText(start, end) {
  if (start === end) return "Date";
  return "Dates";
}

export function formatShortDateRange(start, end) {
  if (start === end) return dateFormatter(start, "MMM d, yyyy");
  const startDate = dateFormatter(start, "MMM d");
  const endDate = dateFormatter(end, "MMM d, yyyy");
  const dateRange = `${startDate} - ${endDate}`;

  return dateRange;
}

export function formatLongDateRange(start, end) {
  if (start === end) return dateFormatter(start, "MMMM d, yyyy");
  return `${formatDate(start)} to ${formatDate(end)}`;
}

export function formatResponseTime(min) {
  if (min && min < 1440) {
    return `${minutesToHours(min)} hours`;
  }
  return "24 hours";
}

export function money(val) {
  const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return moneyFormatter.format(val);
}

export function moneyWithoutDecimals(val) {
  const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return moneyFormatter.format(val).replace(/\D00(?=\D*$)/, "");
}

export function formatTravelersString({ adults, children, infants }) {
  const arr = [];
  if (adults > 0) arr.push(pluralize("adults", adults, true));
  if (children > 0) arr.push(pluralize("children", children, true));
  if (infants > 0) arr.push(pluralize("infants", infants, true));
  return arr.join(", ");
}

export function getTravelersString(numTravelers) {
  if (
    numTravelers &&
    !(
      numTravelers.adults === 0 &&
      numTravelers.children === 0 &&
      numTravelers.infants === 0
    )
  ) {
    return formatTravelersString(numTravelers);
  }
  return "1 traveler";
}

export function isGroup(product) {
  if (
    product.min_guests !== null &&
    product.max_guests !== null &&
    product.min_guests === product.max_guests
  ) {
    return true;
  }
  return false;
}

export function fromPricingSuffix(pricingType) {
  let suffix = "night";
  if (pricingType === "per_person") {
    suffix = "person";
  } else if (pricingType.includes("day")) {
    suffix = "day";
  }
  return suffix;
}

export function fromPricing(price, pricingType) {
  if (price && pricingType) {
    return `From ${moneyWithoutDecimals(price)} / ${fromPricingSuffix(
      pricingType
    )}`;
  }
  return null;
}

export function pluralizeWord(singularWord, pluralWord, count) {
  return count > 1 ? pluralWord : singularWord;
}

export function sanitizedCountryName(countryName) {
  switch (countryName) {
    case "United States":
      return "United States of America";
    case "Taiwan":
      return "Taiwan, Province of China";
    case "England":
      return "united kingdom of great britain and northern ireland";
    default:
      return countryName;
  }
}

export function aggregateRating(reviewScores) {
  const starScores = reviewScores.map((rating) => {
    switch (rating) {
      case 2:
        return 4.95;
      case 1:
        return 4.5;
      case 0:
        return 3;
      default:
        return 0;
    }
  });

  const starSum = starScores.reduce((a, b) => a + b, 0);

  return (starSum / reviewScores.length).toFixed(2);
}

export function formatCheckInOutText(date, time) {
  let timeString = formatDate(date);
  if (time) {
    timeString += ` at ${formatTwelveHrTime(time)}`;
  }
  return timeString;
}

export function formatMonthNames(months, waveTypes) {
  // return a string of comma separated months, with the last two separated by "and", from a Map of boolean => monthName
  const monthNames = [];
  const conditions = [];
  let monthString = "";

  months.forEach((val, key) => {
    if (val) {
      monthNames.push(key);
    }
  });

  Object.entries(waveTypes).forEach(([key, val]) => {
    if (val) {
      conditions.push(key);
    }
  });

  if (monthNames.length > 1) {
    monthString = `${monthNames.slice(0, -1).join(", ")} and ${
      monthNames.slice(-1)[0]
    }`;
  } else {
    [monthString] = monthNames;
  }

  return monthString;
}

export function formatConditionsString(waveTypes) {
  const conditions = [];
  let conditionsString = "";

  Object.entries(waveTypes).forEach(([key, val]) => {
    if (val) {
      conditions.push(key === "Off" ? "Off-peak" : key);
    }
  });

  if (conditions.length > 1) {
    conditionsString = `${conditions.slice(0, -1).join(", ")} and ${
      conditions.slice(-1)[0]
    }`;
  } else if (conditions.length === 1) {
    [conditionsString] = conditions;
  } else {
    conditionsString = "Any";
  }

  return conditionsString;
}

export function formatMaxPrice(val) {
  return val === maxPricePerTraveler() ? `${val}+` : val;
}

export function durationBetweenDates(start, end) {
  return intervalToDuration({
    start: parse(
      `${start}T00:00:00.000Z`,
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      new Date()
    ),
    end: parse(
      `${end}T00:00:00.000Z`,
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      new Date()
    ),
  }).days;
}

export function guidingDaysString(numFullDays, numHalfDays, numQuarterDays) {
  const fullDays = numFullDays > 0 ? `${numFullDays} ${pluralize("full day", numFullDays)}` : null;
  const halfDays = numHalfDays > 0 ? `${numHalfDays} ${pluralize("half day", numHalfDays)}` : null;
  const quarterDays = numQuarterDays > 0 ? `${numQuarterDays} ${pluralize("two-hour session", numQuarterDays)}` : null;
  if (fullDays === null && halfDays === null && quarterDays === null) return null;
  return [fullDays, halfDays, quarterDays].filter(Boolean).join(" and ");
}

export function guidingDaysFullString(numFullDays, numHalfDays, numQuarterDays) {
  const str = guidingDaysString(numFullDays, numHalfDays, numQuarterDays);
  if (str === null) return "Surf guiding"
  return `${str} of surf guiding`;
}

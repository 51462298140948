import PropTypes from "prop-types";
import cn from "classnames";
import { useState, useRef } from "react";
import IconClose from "solar/icons/IconClose";
import IconMenu from "solar/icons/IconMenu";
import Search from "components/shared/stays/Search";
import Save from "components/shared/stays/Save";
import Share from "components/shared/stays/Share";
import NavbarLogo from "components/shared/stays/NavbarLogo";
import NavbarSmallMenu from "components/shared/stays/NavbarSmallMenu";
import NavbarSmallBack from "components/shared/stays/NavbarSmallBack";
import styles from "components/shared/stays/NavbarSmall.module.scss";

export default function NavbarSmall({
  parentName,
  parentPath,
  smallPrimary,
  smallSecondary,
  tripID = null,
  links,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const headerRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSearchToggle = (status) => {
    setIsSearchOpen(status);
  };

  return (
    <>
      <div
        className={cn(styles.component, isOpen && styles.open)}
        ref={headerRef}
      >
        <div className={styles.grid}>
          <div className={cn(styles.primary)}>
            {smallPrimary === "logo" && <NavbarLogo />}
            {smallPrimary === "search" && (
              <div className={styles.search}>
                <Search
                  navToggleCallback={handleSearchToggle}
                  navCloseCallback={() => {
                    setIsOpen(false);
                  }}
                />
              </div>
            )}
            {smallPrimary === "back" && (
              <NavbarSmallBack
                parentName={parentName}
                parentPath={parentPath}
                isListing={tripID && true}
              />
            )}
          </div>
          <div className={cn(styles.secondary)}>
            {smallSecondary === "menu" && (
              <button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                type="button"
                className={styles.button}
              >
                {isOpen ? <IconClose /> : <IconMenu />}
              </button>
            )}
            {smallSecondary === "actions" && (
              <div className={styles.actions}>
                <Share type="trip" />
                <Save tripID={tripID} isListing />
              </div>
            )}
          </div>
        </div>
      </div>
      {smallSecondary === "menu" && !isSearchOpen && (
        <NavbarSmallMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleClose={handleClose}
          links={links}
        />
      )}
    </>
  );
}

NavbarSmall.propTypes = {
  parentName: PropTypes.string.isRequired,
  parentPath: PropTypes.string.isRequired,
  smallPrimary: PropTypes.oneOf(["logo", "search", "back", "none"]).isRequired,
  smallSecondary: PropTypes.oneOf(["menu", "actions", "none"]).isRequired,
  tripID: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      newTab: PropTypes.bool,
      inMenu: PropTypes.bool,
      highlighted: PropTypes.bool,
    })
  ),
};

import PropTypes from "prop-types";
import { formatURL } from "helpers/cloudfrontURLFormatting";
import Image from "next/legacy/image";

export default function Photo({
  src,
  imageBlurSrc,
  alt,
  layout,
  width = null,
  height = null,
  objectFit = "",
  priority = false,
  placeholder = null,
  className = null,
  onLoad = null,
  unoptimized = false,
  containerWidth = null,
}) {
  const formattedSrc = formatURL(src);

  const lqip =
    imageBlurSrc ||
    formatURL(src, {
      resize: { width: 10, height: 10 },
    });

  return (
    <Image
      src={formattedSrc}
      alt={alt}
      sizes={containerWidth && `${containerWidth}px`}
      width={width || 0}
      height={height || 0}
      layout={layout}
      objectFit={objectFit}
      priority={priority}
      onLoad={onLoad}
      unoptimized={unoptimized}
      placeholder={placeholder ? "blur" : "empty"}
      blurDataURL={placeholder && lqip}
      className={className}
      quality={65}
    />
  );
}

Photo.propTypes = {
  alt: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  src: PropTypes.string,
  imageBlurSrc: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  objectFit: PropTypes.string,
  priority: PropTypes.bool,
  placeholder: PropTypes.bool,
  className: PropTypes.node,
  onLoad: PropTypes.func,
  unoptimized: PropTypes.bool,
  containerWidth: PropTypes.number,
};

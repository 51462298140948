import * as React from "react";

function IconArrowSubmit() {
  return (
    <svg
      width={24}
      height={22}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.662.5a.989.989 0 00-.558.173 1.012 1.012 0 00-.367.46 1.03 1.03 0 00.23 1.11l7.621 7.74H1.014a.987.987 0 00-.716.292 1.015 1.015 0 00-.298.723 1.029 1.029 0 00.298.723 1 1 0 00.716.292h19.574l-7.622 7.74a1.018 1.018 0 00-.307.722 1.027 1.027 0 00.293.728.997.997 0 00.717.297.985.985 0 00.71-.312l9.328-9.472a1.023 1.023 0 000-1.436L14.38.808A.998.998 0 0013.664.5z" />
    </svg>
  );
}

export default IconArrowSubmit;

import PropTypes from "prop-types";
import cn from "classnames";
import styles from "solar/components/ButtonImage.module.scss";

export default function ButtonImage({ onClick, children, className = null }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.component, className)}
    >
      {children}
    </button>
  );
}

ButtonImage.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

import Link from "next/link";
import LogoSm from "solar/logos/LogoSm";
import styles from "components/shared/stays/FooterLegal.module.scss";

export default function FooterLegal() {
  return (
    <div className={styles.component}>
      <div className={styles.grid}>
        <div>
          <Link prefetch={false} href="/" className={styles.logoLink}>
            <LogoSm />
          </Link>
        </div>
        <div className={styles.links}>
          <div>©2023 Thermal, Inc.</div>
          <div>
            <Link prefetch={false} href="/privacy">
              Privacy
            </Link>
          </div>
          <div>
            <Link prefetch={false} href="/terms">
              Terms
            </Link>
          </div>
          <div>
            <Link prefetch={false} href="/sitemap.xml">
              Sitemap
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

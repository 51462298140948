import PropTypes from "prop-types";
import cn from "classnames";
import styles from "components/shared/stays/FooterGrid.module.scss";

export default function FooterGrid({ wide = false, children }) {
  return (
    <div className={cn(styles.component, wide ? styles.wide : styles.default)}>
      {children}
    </div>
  );
}

FooterGrid.propTypes = {
  wide: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

import PropTypes from "prop-types";
import cn from "classnames";
import IconSuccess from "solar/icons/IconSuccess";
import IconInfo from "solar/icons/IconInfo";
import IconWarning from "solar/icons/IconWarning";
import styles from "solar/components/AlertToast.module.scss";

export default function AlertToast({ variant, text, className = null }) {
  return (
    <div className={cn(styles.component, className)}>
      {variant && (
        <span className={styles.icon}>
          {variant === "success" && <IconSuccess />}
          {variant === "info" && <IconInfo />}
          {variant === "warning" && <IconWarning />}
        </span>
      )}
      <span className={styles.text}>{text}</span>
    </div>
  );
}

AlertToast.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["success", "info", "warning"]),
  className: PropTypes.node,
};

import PropTypes from "prop-types";
import cn from "classnames";
import IconSuccess from "solar/icons/IconSuccess";
import IconInfo from "solar/icons/IconInfo";
import IconWarning from "solar/icons/IconWarning";
import styles from "solar/components/Alert.module.scss";

export default function Alert({ type, title, children, className = null }) {
  return (
    <div className={cn(styles.component, className)}>
      <div>
        {type === "success" && <IconSuccess />}
        {type === "info" && <IconInfo />}
        {type === "warning" && <IconWarning />}
      </div>
      <div className={styles.description}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{children}</div>
      </div>
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(["success", "info", "warning"]).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

import * as React from "react";

function IconPeace() {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm-1 2.055a9.001 9.001 0 00-6.032 14.563L11 11.586V3.055zm2 0v8.53l6.032 6.033A9.001 9.001 0 0013 3.055zm4.618 15.977L13 14.414v6.531a8.958 8.958 0 004.618-1.913zM11 20.945v-6.53l-4.618 4.617A8.958 8.958 0 0011 20.945z"
      />
    </svg>
  );
}

export default IconPeace;

import PropTypes from "prop-types";
import cn from "classnames";
import ModalContainer from "components/shared/stays/ModalContainer";
import styles from "components/shared/stays/ModalBody.module.scss";

export default function ModalBody({ className = null, children }) {
  return (
    <div className={cn(styles.component, className)}>
      <ModalContainer height100>{children}</ModalContainer>
    </div>
  );
}

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

import { useState } from "react";
import axios from "axios";
import jsCookie from "js-cookie";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Text from "components/shared/stays/Text";
import FormNote from "solar/components/FormNote";
import Alert from "solar/components/Alert";
import TextInputWithSubmit from "solar/components/TextInputWithSubmit";
import styles from "components/shared/stays/FooterSubscribe.module.scss";

export default function FooterSubscribe() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [subscribed, setSubscribed] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Your email is required.")
      .email("A valid email is required."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function submitForm(data) {
    try {
      const utmSource = jsCookie.get("utm-source");

      if (utmSource !== undefined) {
        data.utmSource = utmSource; // eslint-disable-line no-param-reassign
      }

      data.subscribeFormLocation = "Footer"; // eslint-disable-line no-param-reassign

      await axios.post("/api/subscribe", data);
      setSubscribed(true);
    } catch (error) {
      setErrorMessage(error.response.data.errorMsg);
    }
  }
  return (
    <div className={styles.component}>
      {subscribed ? (
        <Text className={styles.text}>
          Thank you! Expect new trips every week or so and the occasional
          surprise.
        </Text>
      ) : (
        <>
          <Text className={styles.text}>
            Stay in touch. Subscribe to our newsletter for updates on new trips,
            discounts and more.
          </Text>
          {errorMessage && (
            <Alert type="warning" title="Let's try that again">
              {errorMessage}
            </Alert>
          )}
          <form
            onSubmit={handleSubmit(submitForm)}
            noValidate
            className={styles.form}
          >
            <TextInputWithSubmit
              id="email"
              type="email"
              name="email"
              register={register}
              placeholder="Email address"
              block
              labelText="Email"
              heap="subscribe"
              variant="light"
            />
            {errors.email && (
              <FormNote hasError>{errors.email.message}</FormNote>
            )}
          </form>
        </>
      )}
    </div>
  );
}

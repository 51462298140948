import { useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/shared/stays/Modal";
import ModalBody from "components/shared/stays/ModalBody";
import ModalHeader from "components/shared/stays/ModalHeader";
import ModalFooter from "components/shared/stays/ModalFooter";
import Title from "components/shared/stays/Title";
import Text from "components/shared/stays/Text";
import Button from "solar/components/Button";
import FormSubtext from "components/auth/FormSubtext";
import LoginForm from "components/auth/LoginForm";
import SignupForm from "components/auth/SignupForm";
import SignupTerms from "components/auth/SignupTerms";
import ForgotPasswordForm from "components/auth/ForgotPasswordForm";
import styles from "components/auth/AuthModal.module.scss";

export default function AuthModal(props) {
  const { show, handleClose } = props;

  const [resetEmailSent, setResetEmailSent] = useState(false);

  const [states, setStates] = useState({
    login: false,
    signup: true,
    forgot: false,
  });

  function showLogin() {
    setStates({
      login: true,
      signup: false,
      forgot: false,
    });
  }

  function showSignup() {
    setStates({
      login: false,
      signup: true,
      forgot: false,
    });
  }

  function showForgot() {
    setStates({
      login: false,
      signup: false,
      forgot: true,
    });
  }

  function closeModal() {
    handleClose();
    showSignup();
  }

  return (
    <Modal show={show} handleClose={closeModal} fixed>
      <ModalHeader>
        <Title size={4}>
          {states.login && "Log in"}
          {states.signup && "Sign up"}
          {states.forgot && "Forgot your password?"}
        </Title>
      </ModalHeader>
      <ModalBody className={styles.body}>
        {states.login && (
          <>
            <LoginForm handleLogin={() => {}} />
            <FormSubtext>
              <Text size={2}>
                <Button link onClick={showForgot}>
                  Forgot your password?
                </Button>
              </Text>
            </FormSubtext>
          </>
        )}
        {states.signup && (
          <>
            <SignupForm handleSignup={() => {}} />
            <FormSubtext>
              <SignupTerms />
            </FormSubtext>
          </>
        )}
        {states.forgot && (
          <ForgotPasswordForm
            resetEmailSent={resetEmailSent}
            setResetEmailSent={setResetEmailSent}
          />
        )}
      </ModalBody>
      <ModalFooter bordered>
        {states.login && (
          <Text>
            {"Don't have an account? "}
            <Button link onClick={showSignup}>
              Sign up
            </Button>
          </Text>
        )}
        {states.signup && (
          <Text>
            {"Already have an account? "}
            <Button link onClick={showLogin}>
              Log in
            </Button>
          </Text>
        )}
        {states.forgot && (
          <Text>
            {"Nevermind, I remember now. "}
            <Button link onClick={showLogin}>
              Log in
            </Button>
          </Text>
        )}
      </ModalFooter>
    </Modal>
  );
}

AuthModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

import PropTypes from "prop-types";
import cn from "classnames";
import styles from "solar/components/TextInputWithLabel.module.scss";

export default function TextInputWithLabel({
  name,
  type,
  register = null,
  labelText,
  defaultValue = "",
  isRequired = false,
  pattern = null,
  maxLength = 524288,
  hasError = false,
  onChange = null,
  onClick = null,
  onFocus = null,
  onBlur = null,
  onKeyDown = null,
  onKeyUp = null,
  onKeyPress = null,
  autoComplete = "",
  block = false,
  pairTop = false,
  pairBottom = false,
  transparent = false,
  className = null,
}) {
  return (
    <div
      className={cn(
        styles.component,
        transparent && styles.transparent,
        className
      )}
    >
      <input
        id={name}
        name={name}
        type={type}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(name, {
          onBlur,
          onChange,
        })}
        defaultValue={defaultValue}
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        pattern={pattern}
        required={isRequired}
        autoComplete={autoComplete}
        placeholder=" " // A blank space is required to make the float label css selector work
        maxLength={maxLength}
        className={cn(
          styles.input,
          pairTop && styles.top,
          pairBottom && styles.bottom,
          block && styles.block,
          hasError && styles.error
        )}
      />
      <label htmlFor={name} className={styles.label}>
        {labelText}
      </label>
    </div>
  );
}

TextInputWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.func,
  labelText: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  isRequired: PropTypes.bool,
  pattern: PropTypes.func,
  maxLength: PropTypes.number,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoComplete: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.node,
  pairTop: PropTypes.bool,
  pairBottom: PropTypes.bool,
  transparent: PropTypes.bool,
};

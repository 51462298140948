import PropTypes from "prop-types";
import { useRef, useState } from "react";
import useDetectOutsideClick from "helpers/hooks/useDetectOutsideClick";
import cn from "classnames";
import NavbarBigLink from "components/shared/stays/NavbarBigLink";
import IconDotsHorizontal from "solar/icons/IconDotsHorizontal";
import styles from "components/shared/stays/NavbarBigLinks.module.scss";

export default function NavbarBigLinks({ links = [], orientation = "left" }) {
  const linksWithoutHome = links.filter((link) => link.text !== "Home");
  const exposedLinks = linksWithoutHome.filter((link) => !link.inMenu);
  const menuLinks = linksWithoutHome.filter((link) => link.inMenu);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  useDetectOutsideClick(buttonRef, dropdownRef, () => setIsActive(false));

  const onClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={styles.component}>
      <div className={styles.links}>
        {exposedLinks.map((link) => (
          <NavbarBigLink
            key={link.text}
            href={link.href}
            text={link.text}
            newTab={link.newTab}
            highlighted={link.highlighted}
          />
        ))}
      </div>
      <div className={styles.wrapper}>
        <button
          type="button"
          onClick={onClick}
          ref={buttonRef}
          style={{ position: "relative", top: "1px" }}
        >
          <IconDotsHorizontal />
        </button>
        <div
          ref={dropdownRef}
          className={cn(
            styles.menu,
            isActive ? styles.active : styles.inactive,
            orientation === "right" && styles.flipped
          )}
        >
          {menuLinks.map((link) => (
            <NavbarBigLink
              key={link.text}
              href={link.href}
              text={link.text}
              newTab={link.newTab}
              inMenu
              highlighted={link.highlighted}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

NavbarBigLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      newTab: PropTypes.bool,
      inMenu: PropTypes.bool,
      highlighted: PropTypes.bool,
    })
  ),
  orientation: PropTypes.string,
};

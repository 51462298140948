import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { useModalContext } from "context/modal-context";
import Overlay from "react-overlays/Modal";
import { RemoveScroll } from "react-remove-scroll";
import Div100vh from "react-div-100vh";
import ModalContainer from "components/shared/stays/ModalContainer";
import ModalClose from "components/shared/stays/ModalClose";
import Title from "components/shared/stays/Title";
import styles from "components/shared/stays/Modal.module.scss";

// Priority options include "low", "normal", "high".
// If an existing modal with a lesser priority is opened at the
// same time as an existing modal, it will revert back to a closed state,
// otherwise, overrideing the existing modal and replacing it.

export default function Modal({
  children,
  show,
  handleClose,
  navigation = null,
  fixed = false,
  controlsOverlay = false,
  controlsLight = false,
  scrollLock = true,
  className = null,
  title = null,
  fixedFooter = null,
  priority = "normal",
  id = null, // use this to set id explicitly for components that rerender, causing the generated id to be overridden
  allowMultiple = false, // this will prevent open/close state from being governed by the context, allowing multiple to be open at the same time
}) {
  const showRef = useRef(null);
  const { currentModal, setCurrentModal } = useModalContext();
  const [modalId, setModalId] = useState(id);

  if (!modalId) {
    setModalId((Math.random() + 1).toString(36).substring(7));
  }

  useEffect(() => {
    if (!allowMultiple) {
      const priorityMap = {
        low: 1,
        normal: 2,
        high: 3,
      };

      // only do this when `show` changes
      if (showRef.current !== show && modalId) {
        showRef.current = show;

        const modalData = {
          id: modalId,
          priority,
          handleClose,
        };

        if (show) {
          // handle if existing modal is open
          if (currentModal) {
            const incomingPriority = priorityMap[modalData.priority];
            const currentPriority = priorityMap[currentModal.priority] || 0;

            if (incomingPriority >= currentPriority) {
              // close the current modal
              currentModal.handleClose();

              // set the new modal
              setCurrentModal(modalData);
            } else {
              // update state for modal that was blocked by a higher priority modal
              modalData.handleClose();
            }
            // handle if there are no other modals
          } else {
            // set the new modal
            setCurrentModal(modalData);
          }
        }

        // hide if the id matches the current id to prevent closing all modals on a `handleClose` callback
        if (!show && modalId === currentModal?.id) {
          setCurrentModal(null);
        }
      }
    }
  }, [
    show,
    modalId,
    priority,
    currentModal,
    setCurrentModal,
    handleClose,
    allowMultiple,
  ]);

  return (
    <Overlay
      show={currentModal?.id === modalId || (allowMultiple && show)}
      onEscapeKeyDown={handleClose}
      className={styles.dialog}
      restoreFocusOptions={{ preventScroll: true }}
    >
      <>
        <div
          role="button"
          className={styles.backdrop}
          onClick={handleClose}
          onChange={null}
          onKeyDown={null}
          tabIndex="-1"
        />
        <RemoveScroll enabled={scrollLock}>
          <Div100vh>
            <div className={styles.content}>
              <div className={cn(styles.box, fixed && styles.fixed, className)}>
                <div
                  className={cn(
                    styles.controls,
                    controlsOverlay && styles.overlay,
                    controlsLight && styles.light
                  )}
                >
                  <ModalContainer>
                    <div
                      className={cn(
                        styles.navigation,
                        title && styles["has-title"]
                      )}
                    >
                      <div className={styles.close}>
                        <ModalClose
                          onClick={handleClose}
                          overlay={controlsOverlay}
                        />
                      </div>
                      {title && (
                        <div className={styles.title}>
                          <Title size={5}>Filters</Title>
                        </div>
                      )}
                      {navigation && <div>{navigation}</div>}
                    </div>
                  </ModalContainer>
                </div>

                <div className={styles.scroll}>{children}</div>
                {fixedFooter}
              </div>
            </div>
          </Div100vh>
        </RemoveScroll>
      </>
    </Overlay>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  navigation: PropTypes.node,
  className: PropTypes.node,
  fixed: PropTypes.bool,
  controlsOverlay: PropTypes.bool,
  controlsLight: PropTypes.bool,
  scrollLock: PropTypes.bool,
  title: PropTypes.string,
  fixedFooter: PropTypes.node,
  priority: PropTypes.string,
  id: PropTypes.string,
  allowMultiple: PropTypes.bool,
};

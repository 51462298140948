import * as React from "react";

function IconArrowLeftSm() {
  return (
    <svg
      width={9}
      height={8}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M.259 4.002c0-.128.056-.253.157-.344L3.282.938a.513.513 0 01.354-.15c.279 0 .48.187.48.44a.445.445 0 01-.144.326L2.99 2.5 1.725 3.596l1.012-.058h5.288c.292 0 .493.19.493.464 0 .27-.201.46-.493.46H2.737l-1.012-.058L2.99 5.5l.982.945a.445.445 0 01.143.327c0 .252-.2.439-.48.439a.505.505 0 01-.344-.141L.416 4.34a.464.464 0 01-.157-.339z" />
    </svg>
  );
}

export default IconArrowLeftSm;

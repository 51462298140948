import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Modal from "components/shared/stays/Modal";
import ModalBody from "components/shared/stays/ModalBody";
import ModalHeader from "components/shared/stays/ModalHeader";
import Title from "components/shared/stays/Title";
import AlertToast from "solar/components/AlertToast";
import IconCopy from "solar/icons/IconCopy";
import IconMail from "solar/icons/IconMail";
import IconWhatsAppColor from "solar/icons/IconWhatsAppColor";
import IconTwitterColor from "solar/icons/IconTwitterColor";
import IconFacebookMessenger from "solar/icons/IconFacebookMessenger";
import IconChatText from "solar/icons/IconChatText";
import ShareModalItem from "components/shared/stays/ShareModalItem";
import styles from "components/shared/stays/ShareModal.module.scss";

export default function ShareModal({ type, show, handleClose }) {
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [url, setUrl] = useState(null);
  const [post, setPost] = useState(null);

  const title = `Share this ${type || "page"} with friends and family`;
  const preface = encodeURI(`Check out this ${type || "page"} on Thermal!`);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text); // rt: fallback that I'm not sure is works
  }

  const handleCopyClick = () => {
    copyTextToClipboard(url)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(() => {
        setErrorMessage(true);
      });
  };

  useEffect(() => {
    setUrl(window.location.href);
    setPost(preface + encodeURI(" ") + url);
  }, [url, preface]);

  return (
    <Modal show={show} handleClose={handleClose} className={styles.modal}>
      <ModalHeader>
        <Title size={4}>{title}</Title>
      </ModalHeader>
      <ModalBody>
        <ShareModalItem
          variant="button"
          text="Copy link"
          icon={<IconCopy />}
          onClick={() => handleCopyClick()}
        />
        <div className={styles.section}>
          <ShareModalItem
            variant="link"
            text="Email"
            icon={<IconMail />}
            href={`mailto:?body=${post}`}
          />
          <ShareModalItem
            variant="link"
            text="SMS"
            icon={<IconChatText />}
            href={`sms:?&body=${post}`}
          />
          <ShareModalItem
            variant="link"
            text="WhatsApp"
            icon={<IconWhatsAppColor />}
            href={`https://wa.me/?text=${post}`}
          />
          <ShareModalItem
            variant="link"
            text="Messenger"
            icon={<IconFacebookMessenger />}
            href={`http://m.me/?ref=${post}`}
          />
          <ShareModalItem
            variant="link"
            text="Twitter"
            icon={<IconTwitterColor />}
            href={`https://twitter.com/intent/tweet?text=${post}`}
          />
        </div>
      </ModalBody>
      {isCopied && <AlertToast variant="success" text="Link copied" />}
      {errorMessage && (
        <AlertToast variant="warning" text="Shoot. Something went wrong." />
      )}
    </Modal>
  );
}

ShareModal.propTypes = {
  type: PropTypes.oneOf(["trip", "wishlist"]),
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

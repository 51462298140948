import PropTypes from "prop-types";
import cn from "classnames";
import { Media } from "solar/components/Media";
import NavbarSmall from "components/shared/stays/NavbarSmall";
import NavbarBig from "components/shared/stays/NavbarBig";
import styles from "components/shared/stays/Navbar.module.scss";

export default function Navbar({
  parentName = "Home",
  parentPath = "/",
  smallPrimary = "logo",
  smallSecondary = "menu",
  bigPrimary = "links",
  bigSecondary = "search",
  bigTertiary = false,
  bigFluid = false,
  bigWide = false,
  tripID = null,
  sticky = false,
  borderBottom = true,
  className = null,
  staysActive = false,
}) {
  const smallLinks = [
    {
      href: "/adventures",
      text: "Adventures",
      newTab: false,
      inMenu: false,
    },
    {
      href: "/stays",
      text: "Stays",
      newTab: false,
      inMenu: false,
    },
    {
      href: "",
      text: "More",
      newTab: false,
      inMenu: false,
      isMenuButton: true,
    },
    {
      href: "/journal",
      text: "Stories",
      newTab: true,
      inMenu: true,
    },
    {
      href: "/collections",
      text: "Collections",
      newTab: false,
      inMenu: true,
    },
    {
      href: "",
      text: "Support",
      newTab: false,
      inMenu: false,
      isChat: true,
    },
    {
      href: "/login",
      text: "Sign in",
      newTab: false,
      inMenu: false,
      unauthenticated: true,
    },
    {
      href: "/traveler/inbox",
      text: "Messages",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
    {
      href: "/reservations/stays",
      text: "Reservations",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
    {
      href: "/wishlists",
      text: "Wishlists",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
    {
      href: "/account",
      text: "Account",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
    {
      href: "/logout",
      text: "Log out",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
  ];

  const bigLinks = [
    {
      href: "/",
      text: "Home",
      newTab: false,
      inMenu: false,
      highlighted: false,
    },
    {
      href: "/adventures",
      text: "Adventures",
      newTab: false,
      inMenu: false,
      highlighted: false,
    },
    {
      href: "/stays",
      text: "Stays",
      newTab: false,
      inMenu: false,
      highlighted: staysActive,
    },
    {
      href: "/journal",
      text: "Stories",
      newTab: true,
      inMenu: true,
      highlighted: false,
    },
    {
      href: "/collections",
      text: "Collections",
      newTab: false,
      inMenu: true,
      highlighted: false,
    },
  ];

  return (
    <nav
      className={cn(
        styles.component,
        borderBottom && styles.borderBottom,
        sticky && styles.sticky,
        className
      )}
    >
      <Media lessThan="lg">
        <NavbarSmall
          parentName={parentName}
          parentPath={parentPath}
          smallPrimary={smallPrimary}
          smallSecondary={smallSecondary}
          tripID={tripID}
          links={smallLinks}
        />
      </Media>
      <Media greaterThanOrEqual="lg">
        <NavbarBig
          bigPrimary={bigPrimary}
          bigSecondary={bigSecondary}
          bigTertiary={bigTertiary}
          bigFluid={bigFluid}
          bigWide={bigWide}
          links={bigLinks}
        />
      </Media>
    </nav>
  );
}

Navbar.propTypes = {
  parentName: PropTypes.string,
  parentPath: PropTypes.string,
  smallPrimary: PropTypes.string.isRequired,
  smallSecondary: PropTypes.string.isRequired,
  bigPrimary: PropTypes.string,
  bigSecondary: PropTypes.string,
  bigTertiary: PropTypes.string,
  bigFluid: PropTypes.bool,
  bigWide: PropTypes.bool,
  tripID: PropTypes.string,
  sticky: PropTypes.bool,
  borderBottom: PropTypes.bool,
  className: PropTypes.node,
  staysActive: PropTypes.bool,
};

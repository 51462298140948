import FooterLink from "components/shared/stays/FooterLink";
import styles from "components/shared/stays/FooterLinks.module.scss";

export default function FooterLinks() {
  return (
    <>
      <ul className={styles.list}>
        <li className={styles.header}>Support</li>
        <FooterLink text="Ask a surfer" variant="intercom" />
        <FooterLink
          text="Contact us"
          href="https://withthermal.notion.site/Thermal-help-center-d728e23d75f94c5e9a4f6d920476a34b"
        />
        <FooterLink
          text="Help center"
          href="https://www.notion.so/withthermal/Thermal-help-center-d728e23d75f94c5e9a4f6d920476a34b#22720245159c434db634d543d751e2b9"
        />
        <FooterLink
          text="Cancellation options"
          href="https://withthermal.notion.site/Cancellation-options-6c54eed9907c43a0a41f2b5e9094b30e"
        />
        <FooterLink
          text="Updates for COVID"
          href="https://withthermal.notion.site/Updates-for-COVID-0394fb53d622464982e6265f42960ff3"
        />
      </ul>
      <div className={styles.divider} />
      <ul className={styles.list}>
        <li className={styles.header}>Community</li>
        <FooterLink text="Journal" href="/journal" />
        <FooterLink text="Collections" href="/collections" />
        <FooterLink
          text="Instagram"
          href="https://instagram.com/thermal.travel"
        />
      </ul>
      <div className={styles.divider} />
      <ul className={styles.list}>
        <li className={styles.header}>Guiding</li>
        <FooterLink
          text="Become a guide"
          href="/become-a-guide"
          variant="external"
        />
        <FooterLink
          text="Explore guiding resources"
          href="https://www.notion.so/withthermal/Thermal-help-center-d728e23d75f94c5e9a4f6d920476a34b#81182504a0884697bd0c8f873a532340"
        />
        <FooterLink
          text="The basics"
          href="https://withthermal.notion.site/Start-here-7a9d6c1f9ef344fba897676bd32d7fa6"
        />
      </ul>
      <div className={styles.divider} />
      <ul className={styles.list}>
        <li className={styles.header}>About</li>
        <FooterLink text="Adventures" href="/adventures" variant="internal" />
        <FooterLink
          text="About Thermal"
          href="/about-thermal"
          variant="external"
        />
        <FooterLink
          text="Careers"
          href="https://withthermal.notion.site/Careers-at-Thermal-ce9509de36d3419e9e7b7af25af88026"
        />
      </ul>
    </>
  );
}

import { useEffect } from "react";

export default function useDetectOutsideClick(buttonRef, safeAreaRef, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // do nothing if clicking buttonRef or safeAreaRef descendent elements
        if (
          !safeAreaRef.current ||
          safeAreaRef.current.contains(event.target) ||
          buttonRef.current.contains(event.target)
        ) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // add ref and handler to effect dependencies
    [buttonRef, safeAreaRef, handler]
  );
}

import { createMedia } from "@artsy/fresnel";

const MediaQueries = createMedia({
  // rt: this should pull from token variable
  breakpoints: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  },
});

// Make styles for injection into the header of the page
export const mediaStyles = MediaQueries.createMediaStyle();

export const { Media, MediaContextProvider } = MediaQueries;

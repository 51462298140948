import PropTypes from "prop-types";
import Container from "solar/components/Container";
import Overlays from "components/shared/stays/Overlays";
import BannerAdventures from "components/shared/stays/BannerAdventures";
import Navbar from "components/shared/stays/Navbar";
import Footer from "components/shared/stays/Footer";
import ChatButton from "components/shared/stays/ChatButton";
import styles from "components/layouts/LayoutDefault.module.scss";

export default function LayoutDefault({ children }) {
  return (
    <div className={styles.component}>
      <Overlays />
      <BannerAdventures />
      <Navbar
        parentName="Home"
        parentPath="/"
        smallPrimary="back"
        smallSecondary="menu"
        bigSecondary="logo"
        bigTertiary="menu"
      />
      <div className={styles.grid}>
        <Container>{children}</Container>
      </div>
      <Footer />
      <ChatButton type="icon-overlay" overlay />
    </div>
  );
}

LayoutDefault.propTypes = {
  children: PropTypes.node.isRequired,
};

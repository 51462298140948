import PropTypes from "prop-types";
import Link from "next/link";
import cn from "classnames";
import styles from "components/shared/stays/NavbarBigMenuItem.module.scss";

export default function NavbarBigMenuItem({
  text,
  href,
  onClick,
  highlight = false,
  external = false,
}) {
  return (
    <div onClick={onClick} onKeyPress={onClick} role="link" tabIndex="-1">
      {external ? (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={cn(styles.item, highlight && styles.highlight)}
        >
          {text}
        </a>
      ) : (
        <Link
          prefetch={false}
          href={href}
          className={cn(styles.item, highlight && styles.highlight)}
        >
          {text}
        </Link>
      )}
    </div>
  );
}

NavbarBigMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  highlight: PropTypes.bool,
  external: PropTypes.bool,
};

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import queryString from "query-string";
import { track } from "utils/heapanalytics";
import { useRouter } from "next/router";
import { useCombobox } from "downshift";
import { RemoveScroll } from "react-remove-scroll";
import { searchKeys } from "helpers/filters";
import { Media } from "solar/components/Media";
import IconDirection45 from "solar/icons/IconDirection45";
import IconMap from "solar/icons/IconMap";
import IconMagnifyingGlass from "solar/icons/IconMagnifyingGlass";
import IconEye from "solar/icons/IconEye";
import IconFlame from "solar/icons/IconFlame";
import IconBell from "solar/icons/IconBell";
import IconWallet from "solar/icons/IconWallet";
import IconLightbulb from "solar/icons/IconLightbulb";
import IconChevronLeft from "solar/icons/IconChevronLeft";
import IconCloseFluid from "solar/icons/IconCloseFluid";
import ChatButton from "components/shared/stays/ChatButton";
import SearchItem from "components/shared/stays/SearchItem";
import styles from "components/shared/stays/Search.module.scss";
import { getTripsForSearch } from "lib/api";

export default function Search({
  trips = [],
  isHome = false,
  navToggleCallback = null,
  navCloseCallback = null,
}) {
  const router = useRouter();
  const { q } = router.query;

  const currentMonthString = new Date().toLocaleString("default", {
    month: "long",
  });
  const defaultItems = [
    {
      name: "I'm flexible",
      slug: "/stays",
      icon: <IconEye />,
    },
    {
      name: "Firing this month",
      slug: `/stays/?surf_season%5B0%5D=${currentMonthString}&surf_season_waves%5B0%5D=Peak`,
      icon: <IconFlame />,
    },
    {
      name: "Just added",
      slug: "/stays?is_featured%5B0%5D=true",
      icon: <IconBell />,
    },
    {
      name: "Under $200/night",
      slug: "/stays/?lowest_price_per_person_per_night%5B0%5D=20&lowest_price_per_person_per_night%5B1%5D=200",
      icon: <IconWallet />,
    },
    {
      name: "Learn to surf",
      slug: "/collections/lets-get-you-up-and-riding/",
      icon: <IconLightbulb />,
    },
  ];

  function sortTrips(inputTrips) {
    return inputTrips
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((d) => ({ ...d, type: "trip" }));
  }

  const [inputItems, setInputItems] = useState(defaultItems);
  const [inputValue, setInputValue] = useState(null);
  const [sortedTrips, setSortedTrips] = useState(sortTrips(trips));
  const [hasText, setHasText] = useState(false);

  useEffect(() => {
    (async function fetchTrips() {
      if (trips.length === 0) {
        const response = await getTripsForSearch();
        if (response.errorMsg) {
          // error
        } else {
          setSortedTrips(sortTrips(response));
        }
      }
    })();
  }, [trips.length]);

  function urlWithFilterParams(item) {
    const { query } = queryString.parseUrl(router.asPath);
    const newQuery = { ...query };

    // remove existing search params from query string
    searchKeys().forEach((key) => {
      delete newQuery[key];
    });

    const filterParams = new URLSearchParams(newQuery).toString();

    const url = `/stays?${
      item.bbox ? `bbox=${encodeURIComponent(item.bbox)}&` : ``
    }lat=${item.lat}&lng=${item.lng}${
      item.place_type.includes("country") ? `&place=${item.place_type}` : ""
    }&q=${item.text}${filterParams && `&${filterParams}`}`;

    return url;
  }

  function createUrl(item) {
    switch (item.type) {
      case "trip":
        return `/stays/${item.slug}?q=${item.name}`;
      case "location":
        return urlWithFilterParams(item);
      default:
        return item.slug;
    }
  }

  function getIcon(item) {
    switch (item.type) {
      case "trip":
        return <IconDirection45 />;
      case "location":
        return <IconMap />;
      default:
        return item.icon;
    }
  }

  async function handleInputValueChange(obj) {
    const val = obj.inputValue;
    setInputValue(val);

    if (val?.length > 0) {
      setHasText(true);
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=${process.env.NEXT_PUBLIC_MAPBOX_API_KEY}&limit=3&types=country,region,district,place,locality`;
      const response = await fetch(endpoint);
      const results = await response.json();
      const locations = results.features?.map((feature) => ({
        name: feature.place_name,
        type: "location",
        bbox: feature.bbox,
        lat: feature.center[1],
        lng: feature.center[0],
        text: feature.text,
        place_type: feature.place_type,
      }));

      const lowerCaseInputValue = val.toString().toLowerCase();
      const filteredTrips = sortedTrips.filter(
        (item) =>
          item.name.toLowerCase().includes(lowerCaseInputValue) ||
          item.address_city?.toLowerCase().includes(lowerCaseInputValue)
      );

      setInputItems([...locations, ...filteredTrips, ...defaultItems]);
    } else {
      setHasText(false);
      setInputItems(defaultItems);
    }
  }

  function handleSelectedItemChange(item) {
    document.activeElement.blur();

    if (navCloseCallback) {
      navCloseCallback();
    }

    if (item.selectedItem.type || item.selectedItem.slug) {
      const targetUrl = createUrl(item.selectedItem);
      track("Search", {
        selectedResult: item.selectedItem.name,
        url: targetUrl,
      });
      router.push(targetUrl);
    }
  }

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    openMenu,
    closeMenu,
    getComboboxProps,
    selectItem,
    selectedItem,
  } = useCombobox({
    id: "search-input",
    items: inputItems,
    itemToString: (item) => (item ? item.name : ""),
    onSelectedItemChange: handleSelectedItemChange,
    onInputValueChange: handleInputValueChange,
    defaultSelectedItem: { name: q || "" },
  });

  useEffect(() => {
    if (navToggleCallback) {
      navToggleCallback(isOpen);
    }
  }, [isOpen, navToggleCallback]);

  const handleClear = () => {
    // clear search UI
    selectItem({ name: "" });

    // redirect to query without search params
    const { query } = queryString.parseUrl(router.asPath);
    const newQuery = { ...query };

    searchKeys().forEach((key) => {
      delete newQuery[key];
    });

    if (router.pathname !== "/stays/[id]") {
      // redirect unless from a trip page
      router.push({ pathname: router.pathname, query: newQuery }, null, {
        shallow: true,
      });
    }
  };

  // add clear button if a default value is present
  useEffect(() => {
    if (selectedItem.name?.length > 0) {
      setHasText(true);
    }
  }, [selectedItem]);

  // set the query as the default value if present
  useEffect(() => {
    if (q) {
      selectItem({ name: q });
    }
  }, [q, selectItem]);

  // clear the search input after an update without a query
  useEffect(() => {
    if (!q) {
      selectItem({ name: "" });
    }
  }, [router.query, q, selectItem]);

  return (
    <div
      className={cn(
        styles.component,
        isHome && styles.home,
        isOpen && styles.open
      )}
    >
      <RemoveScroll enabled={isOpen} className={styles.scroll}>
        <div className={styles.wrapper}>
          <label {...getLabelProps()} hidden>
            Choose a location
          </label>
          <div {...getComboboxProps()} className={styles.box}>
            <Media lessThan="lg">
              {isOpen ? (
                <button
                  type="button"
                  className={styles.icon}
                  onClick={() => closeMenu()}
                >
                  <IconChevronLeft />
                </button>
              ) : (
                <div className={styles.icon}>
                  <IconMagnifyingGlass />
                </div>
              )}
            </Media>
            <Media greaterThanOrEqual="lg">
              <div className={styles.icon}>
                <IconMagnifyingGlass />
              </div>
            </Media>
            <input
              {...getInputProps({
                onFocus: () => {
                  if (!isOpen) {
                    openMenu();
                  }
                },
              })}
              className={styles.input}
              placeholder="Where to?"
              type="text"
            />
            {hasText && (
              <button
                type="button"
                className={styles.clear}
                onClick={handleClear}
              >
                <IconCloseFluid />
              </button>
            )}
          </div>
          <div {...getMenuProps()} className={styles.menu}>
            {isOpen && (
              <div className={styles.list}>
                {inputItems.map((item, index, array) => (
                  <div
                    key={`${item.type}-${item.name}`}
                    {...getItemProps({ item, index })}
                  >
                    <SearchItem
                      text={item.name}
                      inputValue={inputValue}
                      highlighted={highlightedIndex === index}
                      icon={getIcon(item)}
                      hasDivider={
                        array[index - 1] && array[index - 1].type !== item.type
                      }
                      city={item.address_city}
                    />
                  </div>
                ))}
                <div className={styles.pad}>
                  <div className={styles.divider} />
                </div>
                <div className={styles.pad}>
                  Not sure where to go?
                  {` `}
                  <ChatButton type="text-only" text="Ask a surfer" />
                </div>
              </div>
            )}
          </div>
        </div>
      </RemoveScroll>
    </div>
  );
}

Search.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      address_city: PropTypes.string,
    })
  ),
  isHome: PropTypes.bool,
  navToggleCallback: PropTypes.func,
  navCloseCallback: PropTypes.func,
};

import PropTypes from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { forgotPassword } from "lib/api";
import Text from "components/shared/stays/Text";
import Alert from "solar/components/Alert";
import TextInputWithLabel from "solar/components/TextInputWithLabel";
import FormNote from "solar/components/FormNote";
import Button from "solar/components/Button";
import Form from "components/auth/Form";
import FormSection from "components/auth/FormSection";
import FormGroup from "components/auth/FormGroup";
import FormButton from "components/auth/FormButton";
import FormText from "components/auth/FormText";

export default function ForgotPasswordForm(props) {
  const { resetEmailSent, setResetEmailSent } = props;

  const [errorMessage, setErrorMessage] = useState(null);
  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please add your email address."),
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function submitForm(data) {
    const response = await forgotPassword(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      setResetEmailSent(true);
      setErrorMessage(null);
    }
  }

  return (
    <>
      {errorMessage && (
        <Alert type="warning" title="Let's try that again">
          {errorMessage}
        </Alert>
      )}
      {resetEmailSent ? (
        <FormText>
          <Text>
            An email has been sent to your email address. Follow the directions
            in the email to reset your password.
          </Text>
        </FormText>
      ) : (
        <>
          <FormText>
            <Text>
              Enter the email address associated with your account, and we’ll
              email you a link to reset your password.
            </Text>
          </FormText>
          <Form>
            <form onSubmit={handleSubmit(submitForm)} noValidate>
              <FormSection>
                <FormGroup>
                  <TextInputWithLabel
                    id="email"
                    name="email"
                    type="email"
                    register={register}
                    hasError={!!errors.email}
                    labelText="Email"
                    block
                  />
                  {errors?.email && (
                    <FormNote hasError>{errors.email.message}</FormNote>
                  )}
                </FormGroup>
              </FormSection>
              <FormSection>
                <FormButton>
                  <Button
                    size={2}
                    type="submit"
                    isSubmitting={isSubmitting}
                    block
                  >
                    Send reset link
                  </Button>
                </FormButton>
              </FormSection>
            </form>
          </Form>
        </>
      )}
    </>
  );
}

ForgotPasswordForm.propTypes = {
  setResetEmailSent: PropTypes.func.isRequired,
  resetEmailSent: PropTypes.bool.isRequired,
};

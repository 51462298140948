import PropTypes from "prop-types";
import cn from "classnames";
import Text from "components/shared/stays/Text";
import styles from "components/shared/stays/ShareModalItem.module.scss";

export default function ShareModalItem({
  text,
  icon,
  variant,
  href = null,
  onClick = null,
}) {
  const content = (
    <>
      <Text>{text}</Text>
      <div>{icon}</div>
    </>
  );

  return (
    <>
      {variant === "button" && (
        <button
          type="button"
          onClick={onClick}
          className={cn(styles.component, styles.button)}
        >
          {content}
        </button>
      )}
      {variant === "link" && (
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className={cn(styles.component, styles.link)}
        >
          {content}
        </a>
      )}
    </>
  );
}

ShareModalItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["button", "link"]),
  href: PropTypes.string,
  onClick: PropTypes.func,
};

import PropTypes from "prop-types";
import cn from "classnames";
import IconClose from "solar/icons/IconClose";
import styles from "components/shared/stays/ModalClose.module.scss";

export default function ModalClose({ overlay = false, onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.component, overlay && styles.overlay)}
    >
      <IconClose />
    </button>
  );
}

ModalClose.propTypes = {
  onClick: PropTypes.func.isRequired,
  overlay: PropTypes.bool,
};

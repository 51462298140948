import { useState, createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";

const ModalContext = createContext(null);

function ModalProvider({ children }) {
  const [currentModal, setCurrentModal] = useState(null);

  return (
    <ModalContext.Provider
      value={useMemo(
        () => ({ currentModal, setCurrentModal }),
        [currentModal, setCurrentModal]
      )}
    >
      {children}
    </ModalContext.Provider>
  );
}

function useModalContext() {
  const { currentModal, setCurrentModal } = useContext(ModalContext);

  return {
    currentModal,
    setCurrentModal,
  };
}

export { ModalContext, ModalProvider, useModalContext };

ModalProvider.propTypes = {
  children: PropTypes.node,
};

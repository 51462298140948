/* eslint-disable react/no-unknown-property */

import PropTypes from "prop-types";
import cn from "classnames";
import styles from "solar/components/TextInputWithSubmit.module.scss";
import IconArrowSubmit from "solar/icons/IconArrowSubmit";

export default function TextInputWithSubmit({
  name,
  type,
  register = null,
  defaultValue = "",
  isDisabled = false,
  isReadOnly = false,
  isRequired = false,
  pattern = null,
  maxLength = 524288,
  hasError = false,
  placeholder = "",
  onChange = null,
  onClick = null,
  onFocus = null,
  onBlur = null,
  onKeyDown = null,
  onKeyUp = null,
  onKeyPress = null,
  autoComplete = "",
  variant = "default",
  heap = "",
  className = null,
}) {
  return (
    <div
      className={cn(
        styles.component,
        styles[variant],
        isReadOnly && styles["read-only"],
        hasError && styles.error,
        isDisabled && styles.disabled,
        className
      )}
    >
      <input
        id={name}
        name={name}
        type={type}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(name, {
          onBlur,
          onChange,
        })}
        defaultValue={defaultValue}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        pattern={pattern}
        placeholder={placeholder}
        required={isRequired}
        autoComplete={autoComplete}
        maxLength={maxLength}
        className={styles.input}
      />
      <button type="submit" heap={heap} className={styles.button}>
        <IconArrowSubmit />
      </button>
    </div>
  );
}

TextInputWithSubmit.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  pattern: PropTypes.func,
  maxLength: PropTypes.number,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoComplete: PropTypes.string,
  variant: PropTypes.string,
  heap: PropTypes.string,
  className: PropTypes.node,
};

import PropTypes from "prop-types";
import { useState } from "react";
import { useRouter } from "next/router";
import { createWishlist, updateWishlist, deleteWishlist } from "lib/api";
import { useWishlistContext } from "context/wishlist-context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { trackCreateWishlist } from "lib/analytics";
import * as Yup from "yup";
import TextInputWithLabel from "solar/components/TextInputWithLabel";
import Title from "components/shared/stays/Title";
import Text from "components/shared/stays/Text";
import FormNote from "solar/components/FormNote";
import Button from "solar/components/Button";
import Alert from "solar/components/Alert";
import Modal from "components/shared/stays/Modal";
import ModalHeader from "components/shared/stays/ModalHeader";
import ModalBody from "components/shared/stays/ModalBody";
import ModalFooter from "components/shared/stays/ModalFooter";
import styles from "components/shared/stays/SaveModalEdit.module.scss";

export default function SaveModalEdit({
  show,
  handleClose,
  tripID,
  handleSave,
  wishlistSlug,
  wishlistName,
  wishlistID,
  handleError,
  errorMessage = null,
}) {
  const router = useRouter();
  const { fetchWishlists } = useWishlistContext();
  const [isConfirmation, setIsConfirmation] = useState(false);

  const closeAndResetConf = () => {
    setIsConfirmation(false);
    handleClose();
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Please add a list name."),
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function submitCreate(data) {
    const additionalAttributes = {
      trips_wishlists_attributes: [{ trip_id: tripID }],
    };

    const response = await createWishlist({ ...data, ...additionalAttributes });
    if (response.errorMsg) {
      // error
      handleError(response.errorMsg);
    } else {
      // success
      trackCreateWishlist(data.name);
      handleSave();
    }
  }

  async function submitUpdate(data) {
    const response = await updateWishlist(wishlistSlug, data);
    if (response.errorMsg) {
      // error
      handleError(response.errorMsg);
    } else {
      // success
      handleSave();
    }
  }

  async function handleDelete() {
    const response = await deleteWishlist(wishlistSlug, {
      trips_wishlists_attributes: [{ id: wishlistID, _destroy: wishlistID }],
    });
    if (response.errorMsg) {
      // error
      handleError(response.errorMsg);
    } else {
      // success
      router.push("/wishlists");
      fetchWishlists();
    }
  }

  const deleteButton = (
    <button
      type="button"
      onClick={() => {
        setIsConfirmation(true);
      }}
      className={styles.delete}
    >
      Delete
    </button>
  );

  return (
    <Modal
      show={show}
      handleClose={closeAndResetConf}
      className={styles.component}
      navigation={wishlistSlug && !isConfirmation ? deleteButton : null}
    >
      <ModalHeader>
        <Title size={4}>
          {wishlistSlug ? (
            <>
              {isConfirmation && "Delete this wishlist"}
              {!isConfirmation && "Edit this wishlist"}
            </>
          ) : (
            "Name this wishlist"
          )}
        </Title>
      </ModalHeader>
      <form
        id="wishlist-form"
        onSubmit={handleSubmit(wishlistSlug ? submitUpdate : submitCreate)}
        noValidate
        className={styles.form}
      >
        <ModalBody>
          {isConfirmation ? (
            <Text>Are you sure you want to delete {wishlistName}?</Text>
          ) : (
            <>
              <TextInputWithLabel
                name="name"
                type="text"
                register={register}
                labelText="Name"
                hasError={errors.name != null}
                defaultValue={wishlistName || ""}
                maxLength={50}
                block
              />
              <FormNote hasError={errors?.name?.message}>
                {errors?.name?.message
                  ? errors?.name?.message
                  : "50 characters maximum"}
              </FormNote>
              {errorMessage && (
                <Alert type="warning" title="Let's try that again">
                  {errorMessage}
                </Alert>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter bordered>
          {isConfirmation ? (
            <div className={styles.actions}>
              <div>
                <Button
                  variant="link"
                  onClick={() => {
                    setIsConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <Button onClick={handleDelete}>Yes, delete</Button>
            </div>
          ) : (
            <Button
              type="submit"
              variant="black"
              size={2}
              isSubmitting={isSubmitting}
              block
            >
              {tripID ? "Create" : "Update"}
            </Button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
}

SaveModalEdit.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tripID: PropTypes.string,
  wishlistName: PropTypes.string,
  wishlistSlug: PropTypes.string,
  wishlistID: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

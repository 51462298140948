/* eslint-disable import/no-unresolved */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useAuthContext } from "context/auth-context";
import { useIntercom } from "react-use-intercom";
import IconChatQuestion from "solar/icons/IconChatQuestion";
import Button from "solar/components/Button";
import ButtonImage from "solar/components/ButtonImage";
import styles from "components/shared/stays/ChatButton.module.scss";
import crypto from "crypto";

export default function ChatButton({
  type = "icon-overlay",
  text = "Ask a surfer",
  overlay = false,
  className = null,
}) {
  const { show, boot } = useIntercom();
  const user = useAuthContext().getUserData();

  useEffect(() => {
    if (user.email !== undefined) {
      const hmac = crypto.createHmac(
        "sha256",
        process.env.NEXT_PUBLIC_INTERCOM_SECRET
      );
      hmac.update(user.email);
      const hash = hmac.digest("hex");
      boot({
        email: user.email,
        userHash: hash,
      });
    } else {
      boot();
    }
  }, [boot, user.email]);

  return (
    <>
      {type === "icon-overlay" && (
        <ButtonImage onClick={show} className={overlay && styles.overlay}>
          <IconChatQuestion />
        </ButtonImage>
      )}
      {(type === "text-only" || type === "text-with-icon") && (
        <Button
          onClick={show}
          size={1}
          icon={type === "text-with-icon" && <IconChatQuestion />}
          link={type === "text-only"}
          className={className}
        >
          {text}
        </Button>
      )}
      {type === "orange-pill" && (
        <Button onClick={show} size={1} className={className} variant="orange">
          {text}
        </Button>
      )}
      {type === "ghost-icon" && (
        <ButtonImage onClick={show} className={styles.ghost}>
          <IconChatQuestion /> {text}
        </ButtonImage>
      )}
      {type === "button-wrapper" && (
        <button type="button" onClick={show} size={1} className={className}>
          {text}
        </button>
      )}
    </>
  );
}

ChatButton.propTypes = {
  type: PropTypes.oneOf([
    "icon-overlay",
    "text-only",
    "text-with-icon",
    "orange-pill",
    "button-wrapper",
    "ghost-icon",
  ]).isRequired,
  text: PropTypes.string,
  overlay: PropTypes.bool,
  className: PropTypes.node,
};

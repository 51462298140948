export default function useImageWrapper() {
  const getImageWidth = (refs) => {
    if (refs) {
      const widths = refs.map(
        (ref) => ref?.current?.getBoundingClientRect().width
      );
      return widths;
    }
    return null;
  };
  return { getImageWidth };
}

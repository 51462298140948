import Text from "components/shared/stays/Text";
import IconPeace from "solar/icons/IconPeace";
import styles from "components/shared/stays/FooterBenefits.module.scss";

export default function FooterLink() {
  return (
    <div className={styles.component}>
      <div>
        <IconPeace />
      </div>
      <Text>
        Every Thermal stay is backed by risk-free booking, a low price guarantee
        and 24/7 support.
      </Text>
    </div>
  );
}

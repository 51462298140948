import * as React from "react";

function IconMagnifyingGlass() {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18a8 8 0 116.32-3.094l5.387 5.387-1.414 1.414-5.387-5.387A7.965 7.965 0 0110 18zm6-8a6 6 0 11-12 0 6 6 0 0112 0z"
      />
    </svg>
  );
}

export default IconMagnifyingGlass;

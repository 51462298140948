import PropTypes from "prop-types";
import { useState } from "react";
import cn from "classnames";
import IconShare from "solar/icons/IconShare";
import ShareModal from "components/shared/stays/ShareModal";
import styles from "components/shared/stays/Share.module.scss";

export default function Share({ type }) {
  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(false);
  }

  return (
    <>
      <button
        type="button"
        onClick={() => setShow(true)}
        className={cn(styles.button, type === "trip" && styles.floating)}
      >
        <IconShare />
      </button>
      <ShareModal type={type} show={show} handleClose={handleClose} />
    </>
  );
}

Share.propTypes = {
  type: PropTypes.oneOf(["trip", "wishlist"]).isRequired,
};

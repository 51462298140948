import { useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import queryString from "query-string";
import jsCookie from "js-cookie";
import "solar/Global.scss";
import Head from "next/head";
import { AuthProvider } from "context/auth-context";
import { HistoryProvider } from "context/history-context";
import { MediaContextProvider } from "solar/components/Media";
import { IntercomProvider } from "react-use-intercom";
import { WishlistProvider } from "context/wishlist-context";
import { ModalProvider } from "context/modal-context";
import LayoutDefault from "components/layouts/LayoutDefault";
import { init } from "utils/sentry";
import Script from "next/script";
import { GA_TRACKING_ID, GOOGLE_ADWORDS_TRACKING_ID } from "lib/gtag";

init();

export default function MyApp({ Component = undefined, pageProps = {} }) {
  const Layout = Component.Layout || LayoutDefault;

  const router = useRouter();
  const { query } = queryString.parseUrl(router.asPath);

  useEffect(() => {
    const utmSource = query.utm_source;
    if (utmSource !== undefined) {
      jsCookie.set("utm-source", utmSource, { expires: 30 });
    }
  }, [query.utm_source]);

  return (
    <AuthProvider>
      <WishlistProvider>
        <HistoryProvider>
          <ModalProvider>
            <IntercomProvider
              appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
              initializeDelay={5000}
            >
              <MediaContextProvider disableDynamicMediaQueries>
                <Layout>
                  <Head>
                    <meta charSet="UTF-8" />
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1"
                    />
                    <link
                      rel="apple-touch-icon"
                      sizes="180x180"
                      href="/favicon/apple-touch-icon.png"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="32x32"
                      href="/favicon/favicon-32x32.png"
                    />
                    <link
                      rel="icon"
                      type="image/png"
                      sizes="16x16"
                      href="/favicon/favicon-16x16.png"
                    />
                    <link rel="manifest" href="/favicon/site.webmanifest" />
                    <meta
                      name="msapplication-config"
                      content="/favicon/browserconfig.xml"
                    />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="theme-color" content="#ffffff" />
                  </Head>
                  {/* Heap */}
                  <Script
                    id="script-heap"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
            heap.load(${process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID});
          `,
                    }}
                  />

                  {/* Google */}

                  <Script
                    strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
                  />

                  <Script
                    id="script-gtm"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            gtag('config', '${GOOGLE_ADWORDS_TRACKING_ID}');
          `,
                    }}
                  />

                  {/* Facebook */}
                  <Script
                    id="script-facebook"
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                      __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${process.env.NEXT_PUBLIC_FB_PIXEL_ID});
                fbq('track', 'PageView');
              `,
                    }}
                  />

                  {/* Klaviyo */}

                  <Script
                    id="script-klaviyo"
                    strategy="afterInteractive"
                    src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_KEY}`}
                  />

                  <Script
                    id="load-klaviyo-obj"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();`,
                    }}
                  />

                  <noscript>
                    <img
                      height="1"
                      width="1"
                      style={{ display: "none" }}
                      src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FB_PIXEL_ID}&ev=PageView&noscript=1`}
                      alt="fb"
                    />
                  </noscript>
                  <Component
                    {...pageProps} // eslint-disable-line react/jsx-props-no-spreading
                  />
                </Layout>
              </MediaContextProvider>
            </IntercomProvider>
          </ModalProvider>
        </HistoryProvider>
      </WishlistProvider>
    </AuthProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.oneOfType([PropTypes.object]),
};

import jsCookie from "js-cookie";
import Cookies from "cookies";

export function getAccessToken() {
  return jsCookie.get("access-token");
  // return localStorage.getItem("token");
}

export function setAccessToken(token) {
  jsCookie.set("access-token", token);
  // localStorage.setItem("token", token);
}

export function setUserPhone(phone) {
  jsCookie.set("user-phone", phone);
}

export function getUserPhone() {
  return jsCookie.get("user-phone");
}

export function removeTokensClientSide() {
  jsCookie.remove("refresh-token");
  jsCookie.remove("access-token");
}

export function removeTokensServerSide(req, res) {
  const cookies = new Cookies(req, res);
  cookies.set("refresh-token");
  cookies.set("access-token");
}

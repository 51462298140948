import PropTypes from "prop-types";
import cn from "classnames";
import styles from "solar/components/LoaderDots.module.scss";

export default function LoaderDots(props) {
  const { small, variant } = props;

  return (
    <span className={cn(styles.dots, small && styles.small, styles[variant])}>
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.dot} />
    </span>
  );
}

LoaderDots.propTypes = {
  small: PropTypes.bool,
  variant: PropTypes.string,
};

LoaderDots.defaultProps = {
  small: false,
  variant: "default",
};

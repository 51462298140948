/* eslint-disable react/no-unknown-property */

import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import LoaderDots from "solar/components/LoaderDots";
import styles from "solar/components/Button.module.scss";

export default function Button({
  children,
  link = false,
  type = "button",
  variant = "black",
  size = 2,
  ghost = false,
  responsive = false,
  block = false,
  isDisabled = false,
  isSubmitting = false,
  className = null,
  form = null,
  onClick = null,
  icon = null,
  heap = null,
  truncate = null,
}) {
  const mapVariant = {
    black: styles.black,
    "off-white": styles["off-white"],
    white: styles.white,
    orange: styles.orange,
    link: styles.link,
    lime: styles.lime,
  };

  const mapSize = {
    1: styles.small,
    2: styles.medium,
    3: styles.large,
  };

  return (
    <button
      onClick={onClick}
      type={type === "button" ? "button" : "submit"}
      disabled={isDisabled || isSubmitting}
      form={form}
      heap={heap}
      className={cn(
        styles.component,
        link
          ? styles.link
          : cn(
              styles.default,
              mapSize[size],
              mapVariant[variant],
              ghost && styles.ghost,
              responsive && styles.responsive,
              block && styles.block,
              truncate && styles.truncate
            ),
        isSubmitting && styles.loading,
        className
      )}
    >
      {link ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
      ) : (
        <>
          <span
            className={cn(icon && styles.iconed, isSubmitting && styles.hide)}
          >
            {icon && <span className={styles.icon}>{icon}</span>}
            <span className={styles.text}>{children}</span>
          </span>
          {isSubmitting && (
            <span className={styles.dots}>
              <LoaderDots variant={ghost ? "inverse" : ""} small={size === 1} />
            </span>
          )}
        </>
      )}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.number,
  ghost: PropTypes.bool,
  responsive: PropTypes.bool,
  block: PropTypes.bool,
  truncate: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  className: PropTypes.node,
  form: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  heap: PropTypes.string,
};

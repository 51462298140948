/* eslint-disable arrow-body-style */
import axios from "axios";
import Router from "next/router";
import { getAccessToken } from "helpers/userAuth";

function authString(token = getAccessToken()) {
  return `Bearer ${token}`;
}

function isInvalidOrExpiredAccessToken(response) {
  return (
    response.status === 401 &&
    response.data.error === "Invalid or expired access token"
  );
}

function handleClientRefresh(error, redirectOnLogout) {
  if (isInvalidOrExpiredAccessToken(error.response)) {
    return axios
      .post("/api/refresh")
      .then(() => {
        const { config } = error.response;
        // NOTE: access token set in /api/refresh
        config.headers.Authorization = authString();
        return axios(config);
      })
      .catch((err) => {
        axios.get("/api/logout");
        if (redirectOnLogout) Router.replace("/login");
        return Promise.reject(err);
      });
  }
  // for normal error
  return Promise.reject(error);
}

export function clientAxiosInstance(redirectOnLogout) {
  const instance = axios.create();
  if (getAccessToken())
    instance.defaults.headers.common.Authorization = authString();
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return handleClientRefresh(error, redirectOnLogout);
    }
  );
  return instance;
}

function handleServerRefresh(error, cookies) {
  const refreshToken = cookies.get("refresh-token");
  if (isInvalidOrExpiredAccessToken(error.response) && refreshToken) {
    return axios
      .post(
        "/refresh",
        {},
        {
          baseURL: process.env.NEXT_PUBLIC_API_URL,
          headers: {
            "X-Refresh-Token": refreshToken,
          },
        }
      )
      .then((response) => {
        const { access } = response.data.data.attributes;
        const { config } = error.response;
        cookies.set("access-token", access, { httpOnly: false });
        config.headers.Authorization = authString(access);
        return axios(config);
      })
      .catch((err) => {
        // remove access and refresh token (neither are valid)
        if (isInvalidOrExpiredAccessToken(err.response)) {
          cookies.set("access-token");
          cookies.set("refresh-token");
        }
        return Promise.reject(err);
      });
  }
  // for normal error
  return Promise.reject(error);
}

export function serverAxiosInstance(cookies) {
  const instance = axios.create();
  instance.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;
  if (getAccessToken())
    instance.defaults.headers.common.Authorization = authString();
  if (cookies.get("access-token"))
    instance.defaults.headers.common.Authorization = `Bearer ${cookies.get(
      "access-token"
    )}`;
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return handleServerRefresh(error, cookies);
    }
  );
  return instance;
}

import PropTypes from "prop-types";

export default function IconHeartFilled({
  borderColor = "#ffffff",
  fillColor = "#000000",
  opacity = 1.0,
}) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.103 2.696c1.07-.524 2.003-.696 3.45-.696C20.258 2.015 23 5.14 23 9.12c0 3.038-1.694 5.972-4.849 8.81-1.656 1.49-3.77 2.963-5.285 3.747l-.866.45-.866-.45c-1.515-.784-3.63-2.258-5.285-3.747C2.694 15.092 1 12.158 1 9.12 1 5.097 3.716 2 7.455 2c1.395 0 2.376.189 3.467.728a6.3 6.3 0 011.068.668 6.166 6.166 0 011.113-.7z"
        fill={borderColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.814 16.443C19.586 13.949 21 11.5 21 9.12c0-2.931-1.912-5.11-4.45-5.12-1.163 0-1.804.118-2.568.492-.47.23-.887.54-1.25.936l-.73.79-.736-.784a4.265 4.265 0 00-1.23-.913C9.247 4.13 8.563 4 7.454 4 4.884 4 3 6.148 3 9.12c0 2.38 1.414 4.83 4.186 7.323 1.512 1.36 3.455 2.718 4.814 3.43 1.359-.712 3.302-2.07 4.814-3.43z"
        fill={fillColor}
      />
    </svg>
  );
}

IconHeartFilled.propTypes = {
  borderColor: PropTypes.string,
  fillColor: PropTypes.string,
  opacity: PropTypes.number,
};

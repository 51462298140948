import PropTypes from "prop-types";
import { useEffect, useState, createContext, useContext, useMemo } from "react";
import useUserAuth from "helpers/hooks/useUserAuth";
import { getWishlists } from "lib/api";

const WishlistContext = createContext(null);

function WishlistProvider({ children }) {
  const authenticated = useUserAuth();
  const [wishlists, setWishlists] = useState([]);
  const [savedTrips, setSavedTrips] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  async function fetchWishlists() {
    const response = await getWishlists(false);
    if (response.errorMsg) {
      // error
    } else {
      // success
      setWishlists(response);

      const ids = response
        .filter((list) => list.trips.length > 0)
        .map((list) => list.trips.map((trip) => trip.id));

      const allTrips = [].concat(...ids);

      sessionStorage.setItem("hasSavedTrips", "true");
      localStorage.setItem("savedTrips", JSON.stringify(allTrips));
      localStorage.setItem("wishlists", JSON.stringify(response));
      setSavedTrips(allTrips);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    if (authenticated) {
      // get from sessionStorage so fresh data is fetched in case user edits wishlists in another tab
      const sessionData = sessionStorage.getItem("hasSavedTrips");

      if (!sessionData && !isFetching) {
        // if local data doesnt exist, fetch and save it
        setIsFetching(true);
        fetchWishlists();
      } else if (sessionData && !isFetching) {
        // if local data does exist, use it
        setSavedTrips(JSON.parse(localStorage.getItem("savedTrips")));
        setWishlists(JSON.parse(localStorage.getItem("wishlists")));
      }
    } else {
      setSavedTrips([]);
      setWishlists([]);
    }
  }, [authenticated, isFetching]);

  return (
    <WishlistContext.Provider
      value={useMemo(
        () => [savedTrips, wishlists, fetchWishlists],
        [savedTrips, wishlists]
      )}
    >
      {children}
    </WishlistContext.Provider>
  );
}

function useWishlistContext() {
  const [savedTrips, wishlists, fetchWishlists] = useContext(WishlistContext);

  return {
    savedTrips,
    wishlists,
    fetchWishlists,
  };
}

export { WishlistProvider, useWishlistContext };

WishlistProvider.propTypes = {
  children: PropTypes.node,
};

import * as React from "react";

function IconCloseFluid() {
  return (
    <svg
      width="100%"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.636 1.596L14.222.182 8 6.404 1.778.182.364 1.596l6.222 6.222L0 14.404l1.414 1.414L8 9.232l6.586 6.586L16 14.404 9.414 7.818l6.222-6.222z"
      />
    </svg>
  );
}

export default IconCloseFluid;

import PropTypes from "prop-types";
import styles from "components/shared/stays/SaveModalListNew.module.scss";

export default function SaveModalListNew({ handleShowNew }) {
  return (
    <li className={styles.component}>
      <button type="button" onClick={handleShowNew} className={styles.link}>
        <div className={styles.thumbnail}>+</div>
        Create a new wishlist
      </button>
    </li>
  );
}

SaveModalListNew.propTypes = {
  handleShowNew: PropTypes.func,
};

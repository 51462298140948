import Text from "components/shared/stays/Text";

export default function SignupTerms() {
  return (
    <Text size={3}>
      {"We take your privacy seriously. By clicking Sign up, you agree to our "}
      <a href="/privacy" target="_blank">
        privacy policy
      </a>
      {" and "}
      <a href="/terms" target="_blank">
        terms
      </a>
      .
    </Text>
  );
}

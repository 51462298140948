import PropTypes from "prop-types";
import cn from "classnames";
import ModalContainer from "components/shared/stays/ModalContainer";
import styles from "components/shared/stays/ModalHeader.module.scss";

export default function ModalHeader(props) {
  const { bordered, className, children } = props;

  return (
    <div
      className={cn(styles.component, bordered && styles.bordered, className)}
    >
      <ModalContainer>{children}</ModalContainer>
    </div>
  );
}

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  bordered: PropTypes.bool,
  className: PropTypes.node,
};

ModalHeader.defaultProps = {
  className: null,
  bordered: false,
};

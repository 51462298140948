import PropTypes from "prop-types";
import ModalHeader from "components/shared/stays/ModalHeader";
import ModalBody from "components/shared/stays/ModalBody";
import Title from "components/shared/stays/Title";
import Alert from "solar/components/Alert";
import Modal from "components/shared/stays/Modal";
import SaveModalListItem from "components/shared/stays/SaveModalListItem";
import SaveModalListNew from "components/shared/stays/SaveModalListNew";
import styles from "components/shared/stays/SaveModalList.module.scss";

export default function SaveModalList({
  show,
  handleClose,
  tripID,
  wishlists,
  handleSave,
  handleShowNew,
  handleError,
  errorMessage,
}) {
  return (
    <Modal show={show} handleClose={handleClose} className={styles.component}>
      <ModalHeader>
        <Title size={4}> Your wishlists</Title>
      </ModalHeader>
      <ModalBody>
        <ul className={styles.list}>
          <SaveModalListNew handleShowNew={handleShowNew} />
          {wishlists.map((item) => (
            <SaveModalListItem
              key={item.slug}
              name={item.name}
              slug={item.slug}
              trips={item.trips}
              tripID={tripID}
              handleSave={handleSave}
              handleError={handleError}
            />
          ))}
        </ul>
        {errorMessage && (
          <Alert type="warning" title="Let's try that again">
            {errorMessage}
          </Alert>
        )}
      </ModalBody>
    </Modal>
  );
}

SaveModalList.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tripID: PropTypes.string.isRequired,
  wishlists: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      trips: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          main_image: PropTypes.shape({
            byte_size: PropTypes.number,
            content_type: PropTypes.string,
            height: PropTypes.number,
            width: PropTypes.number,
            url: PropTypes.string.isRequired,
          }),
          media_images: PropTypes.arrayOf(
            PropTypes.shape({
              byte_size: PropTypes.number,
              content_type: PropTypes.string,
              height: PropTypes.number,
              width: PropTypes.number,
              url: PropTypes.string.isRequired,
            })
          ),
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  handleSave: PropTypes.func.isRequired,
  handleShowNew: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  handleError: PropTypes.func.isRequired,
};

/* eslint-disable no-undef */
export function setKlaviyoUser({ email }) {
  if (typeof klaviyo !== "undefined") {
    klaviyo.identify({ $email: email }, () => {
      klaviyo.track("Logged in");
    });
  }
}

export function trackKlaviyo(eventName, dataProps) {
  setTimeout(() => {
    if (typeof klaviyo !== "undefined") {
      klaviyo.isIdentified((result) => {
        if (result) {
          klaviyo.track(eventName, dataProps);
        }
      });
    }
  }, 500);
}

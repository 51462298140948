import PropTypes from "prop-types";
import styles from "components/shared/stays/ModalContainer.module.scss";

export default function ModalContainer({ children }) {
  return <div className={styles.component}>{children}</div>;
}

ModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

import PropTypes from "prop-types";
import cn from "classnames";
import styles from "components/shared/stays/Text.module.scss";

const mapSize = {
  1: styles[`text-1`],
  2: styles[`text-2`],
  3: styles[`text-3`],
  4: styles[`text-4`],
};

export default function Text({
  children,
  size = 1,
  tag = null,
  mono = false,
  className = null,
}) {
  const Tag = tag || "div";

  return (
    <Tag
      className={cn(
        styles.component,
        mono && styles.mono,
        mapSize[size],
        className
      )}
    >
      {children}
    </Tag>
  );
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.number,
  tag: PropTypes.string,
  mono: PropTypes.bool,
  className: PropTypes.node,
};

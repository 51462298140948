import Link from "next/link";
import LogoSm from "solar/logos/LogoSm";
import styles from "components/shared/stays/NavbarLogo.module.scss";

export default function NavbarLogo() {
  return (
    <div className={styles.component}>
      <Link prefetch={false} href="/" className={styles.link}>
        <LogoSm />
      </Link>
    </div>
  );
}

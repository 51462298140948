import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { wishlistRemoveTrip } from "lib/api";
import useUserAuth from "helpers/hooks/useUserAuth";
import { trackSaveTrip } from "lib/analytics";
import { useWishlistContext } from "context/wishlist-context";
import AuthModal from "components/auth/AuthModal";
import SaveButton from "components/shared/stays/SaveButton";
import SaveModalList from "components/shared/stays/SaveModalList";
import SaveModalEdit from "components/shared/stays/SaveModalEdit";

export default function Save({ tripID, isListing = false }) {
  const authenticated = useUserAuth();
  const [showAuth, setShowAuth] = useState(false);
  const [showSaveModalList, setShowSaveModalList] = useState(false);
  const [showSaveModalEdit, setShowSaveModalEdit] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { savedTrips, wishlists, fetchWishlists } = useWishlistContext();

  // clear error after switching views
  useEffect(() => {
    setErrorMessage(null);
  }, [showSaveModalList, showSaveModalEdit]);

  // check if trip has already been saved
  useEffect(() => {
    setIsSaved(savedTrips.includes(parseInt(tripID, 10)));
  }, [savedTrips, tripID]);

  useEffect(() => {
    // close modal when saved state changes so that when
    // someone logs in with an already saved trip they cant save it again.
    setShowSaveModalList(false);
  }, [isSaved]);

  const handleShowNew = () => setShowSaveModalEdit(true);
  const handleCloseAuth = () => setShowAuth(false);
  const handleModalClose = () => {
    setShowSaveModalList(false);
  };

  const handleEditModalClose = () => {
    setShowSaveModalEdit(false);
  };

  const handleModalOpen = () => {
    if (authenticated) {
      setShowSaveModalList(true);
    } else {
      setShowSaveModalList(true);
      setShowAuth(true);
    }
  };

  const handleSave = () => {
    handleModalClose();
    handleEditModalClose();
    setIsSaved(true);
    trackSaveTrip(tripID);
    fetchWishlists();
  };

  async function unsaveTrip(slug) {
    const response = await wishlistRemoveTrip(slug, tripID);
    if (response.errorMsg) {
      // error
      setErrorMessage(response.errorMsg);
    } else {
      // success
      setIsSaved(false);
      fetchWishlists();
    }
  }

  const handleUnsave = () => {
    wishlists.forEach((list) => {
      list.trips.forEach((trip) => {
        if (trip.id === parseInt(tripID, 10)) {
          unsaveTrip(list.slug);
        }
      });
    });
  };

  return (
    <>
      <SaveButton
        isSaved={isSaved}
        isListing={isListing}
        handleClick={isSaved ? handleUnsave : handleModalOpen}
      />
      {authenticated ? (
        <>
          <SaveModalEdit
            show={showSaveModalEdit}
            handleClose={handleEditModalClose}
            tripID={tripID}
            handleSave={handleSave}
            handleError={setErrorMessage}
            errorMessage={errorMessage}
          />
          <SaveModalList
            show={showSaveModalList}
            handleClose={handleModalClose}
            tripID={tripID}
            wishlists={wishlists}
            handleSave={handleSave}
            handleShowNew={handleShowNew}
            handleError={setErrorMessage}
            errorMessage={errorMessage}
          />
        </>
      ) : (
        <AuthModal show={showAuth} handleClose={handleCloseAuth} />
      )}
    </>
  );
}

Save.propTypes = {
  tripID: PropTypes.string.isRequired,
  isListing: PropTypes.bool,
};

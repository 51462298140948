export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
export const GOOGLE_ADWORDS_TRACKING_ID =
  process.env.NEXT_PUBLIC_GOOGLE_ADWORDS_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// export const event = ({ action, category, label, value }) => {
//  window.gtag("event", action, {
//    event_category: category,
//    event_label: label,
//    value,
//  });
// };

// https://support.google.com/analytics/answer/9310895?hl=en
export const event = (eventName, properties = {}) => {
  window.gtag("event", eventName, properties);
};

// https://support.google.com/google-ads/answer/7548399?hl=en
export const googleAdConversion = (conversionLabel, properties = {}) => {
  properties.send_to = `${GOOGLE_ADWORDS_TRACKING_ID}/${conversionLabel}`; // eslint-disable-line no-param-reassign
  window.gtag("event", "conversion", properties);
  // window.gtag("event", "conversion", {
  //   send_to: `${GOOGLE_ADWORDS_TRACKING_ID}/${conversionLabel}`,
  // });
};

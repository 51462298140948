import * as React from "react";

function IconSuccess() {
  return (
    <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z"
        fill="#5AB858"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12.586l5.293-5.293 1.414 1.414L10 15.414l-3.707-3.707 1.414-1.414L10 12.586z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconSuccess;

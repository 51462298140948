import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { wishlistAddTrip } from "lib/api";
import Photo from "components/shared/stays/Photo";
import useImageWrapper from "helpers/hooks/useImageWrapper";
import styles from "components/shared/stays/SaveModalListItem.module.scss";

export default function SaveModalListItem({
  name,
  slug,
  trips,
  tripID,
  handleSave,
  handleError,
}) {
  const { getImageWidth } = useImageWrapper();
  const [imgWidths, setImgWidths] = useState([0]);
  const imgWrapperRef = useRef(null);
  const coverImageSrc = trips[0]?.main_image.url || null;

  useEffect(() => {
    const calculatedWidth = getImageWidth([imgWrapperRef]);
    setImgWidths(calculatedWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function saveTrip() {
    const response = await wishlistAddTrip(slug, tripID);
    if (response.errorMsg) {
      // error
      handleError("There was a problem saving this trip.");
    } else {
      // success
      handleSave();
    }
  }

  return (
    <li className={styles.component}>
      <button className={styles.link} onClick={saveTrip} type="button">
        <div className={styles.thumbnail} ref={imgWrapperRef}>
          {coverImageSrc && (
            <Photo
              src={coverImageSrc}
              alt={name}
              layout="fill"
              objectFit="cover"
              placeholder
              containerWidth={imgWidths[0]}
            />
          )}
        </div>
        {name}
      </button>
    </li>
  );
}

SaveModalListItem.propTypes = {
  name: PropTypes.string,
  slug: PropTypes.string,
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      main_image: PropTypes.shape({
        url: PropTypes.string,
        content_type: PropTypes.string,
        byte_size: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
      }),
      media_images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          content_type: PropTypes.string,
          byte_size: PropTypes.number,
          height: PropTypes.number,
          width: PropTypes.number,
        })
      ),
    })
  ),
  tripID: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

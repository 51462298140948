import PropTypes from "prop-types";
import cn from "classnames";
import Container from "solar/components/Container";
import FooterBenefits from "components/shared/stays/FooterBenefits";
import FooterGrid from "components/shared/stays/FooterGrid";
import FooterLinks from "components/shared/stays/FooterLinks";
import FooterSubscribe from "components/shared/stays/FooterSubscribe";
import FooterLegal from "components/shared/stays/FooterLegal";
import styles from "components/shared/stays/Footer.module.scss";

export default function Footer({
  wide = false,
  fluid = false,
  justLegal = false,
  className = null,
}) {
  return (
    <div className={cn(styles.component, justLegal && styles.slim, className)}>
      <Container fluid={fluid} wide={wide}>
        {!justLegal && (
          <>
            <FooterBenefits />
            {/* <div className={styles.dividerTop} /> */}
            <FooterGrid wide={wide}>
              <FooterLinks />
              <FooterSubscribe />
            </FooterGrid>
            <div className={styles.dividerBottom} />
          </>
        )}
        <FooterLegal />
      </Container>
    </div>
  );
}

Footer.propTypes = {
  wide: PropTypes.bool,
  fluid: PropTypes.bool,
  justLegal: PropTypes.bool,
  className: PropTypes.node,
};

import PropTypes from "prop-types";
import styles from "components/auth/FormSection.module.scss";

export default function FormSection({ children }) {
  return <div className={styles.component}>{children}</div>;
}

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
};

import PropTypes from "prop-types";
import cn from "classnames";
import IconHeartSmart from "solar/icons/IconHeartSmart";
import styles from "components/shared/stays/SaveButton.module.scss";

export default function SaveButton({ isListing, isSaved, handleClick }) {
  return (
    <div className={styles.component}>
      <button
        type="button"
        onClick={handleClick}
        className={cn(styles.button, isListing && styles.overlay)}
      >
        {isListing ? (
          <IconHeartSmart
            borderColor={isSaved ? styles.orange : styles.black}
            fillColor={isSaved ? styles.orange : styles.offWhite}
          />
        ) : (
          <IconHeartSmart
            borderColor={isSaved ? styles.white : styles.white}
            fillColor={isSaved ? styles.orange : styles.black}
            opacity={isSaved ? 1.0 : 0.3}
          />
        )}
      </button>
    </div>
  );
}

SaveButton.propTypes = {
  isListing: PropTypes.bool.isRequired,
  isSaved: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
};

import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import {
  getAccessToken,
  setAccessToken,
  getUserPhone,
  setUserPhone,
} from "helpers/userAuth";

const AuthContext = React.createContext();

function AuthProvider(props) {
  // state used just to propagate user state change
  const [user, setUser] = useState({}); // eslint-disable-line no-unused-vars

  const getUserData = () => {
    // user data taken from token in cookie
    const token = getAccessToken();
    const phone = getUserPhone();
    if (token) {
      const userObj = jwtDecode(token);
      return {
        id: userObj.user_id,
        email: userObj.email,
        phone,
      };
    }
    return {};
  };

  const setUserData = (token) => {
    setAccessToken(token);
    const userObj = jwtDecode(token);
    // save user phone to cookie
    setUserPhone(userObj.phone_number);

    // set user in state so value change propagated
    setUser({
      id: userObj.user_id,
      email: userObj.email,
    });
  };

  const updateUserData = (key, value) => {
    // update user data with passed in value
    const userData = getUserData();

    setUser({
      ...userData,
      [key]: value,
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    getUserData,
    setUserData,
    updateUserData,
  };

  return <AuthContext.Provider value={value} {...props} />; // eslint-disable-line react/jsx-props-no-spreading
}

function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuthContext must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuthContext };

import * as React from "react";

function IconMenu() {
  return (
    <svg
      width={20}
      height={14}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2V0H0v2h20zm0 4v2H0V6h20zm0 6v2H0v-2h20z"
      />
    </svg>
  );
}

export default IconMenu;

/* eslint-disable import/prefer-default-export */
import axios from "axios";

export function formatURL(imageURL, edits = {}) {
  if (imageURL && imageURL.indexOf("cloudfront.net/") !== -1) {
    // production - use image resizer and cloudfront
    const key = imageURL.split("cloudfront.net/")[1];
    const imageRequest = JSON.stringify({
      bucket: "thermal-web-images",
      key,
      edits,
    });
    return (
      imageURL.split(key)[0] + Buffer.from(imageRequest).toString("base64")
    );
  }
  // dev - use url (which will be S3 link)
  return imageURL;
}

export async function getBase64(imageURL) {
  const response = await axios.get(imageURL, { responseType: "arraybuffer" });
  const contentType = response.headers["content-type"];
  return `data:${contentType};base64,${Buffer.from(response.data).toString(
    "base64"
  )}`;
}

import * as React from "react";

function IconShare() {
  return (
    <svg width={24} height={24} fill="#000" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 16V5.414l3.293 3.293 1.414-1.414L12 1.586 6.293 7.293l1.414 1.414L11 5.414V16h2zm8 4v-9h-2v9H5v-9H3v9a2 2 0 002 2h14a2 2 0 002-2z"
      />
    </svg>
  );
}

export default IconShare;

import * as React from "react";

function IconWarning() {
  return (
    <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z"
        fill="#FF4633"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 16.998a1 1 0 100-2 1 1 0 000 2zM10.003 4.998h2v8h-2v-8z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconWarning;

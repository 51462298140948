import * as React from "react";

function IconFlame() {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.199 12.25c.546-.766.485-1.574.106-3.027-.75-2.873-.45-4.448 2.133-6.313l1.26-.91.305 1.525c.325 1.62.857 2.623 2.223 4.55l.11.154c1.968 2.777 2.687 4.452 2.687 7.492 0 3.688-3.729 7-8 7-4.272 0-8-3.311-8-7 0-.069 0-.067-.012-.372-.09-2.356.346-4.2 2.109-6.192.372-.421.796-.825 1.272-1.212l1.052-.855.506 1.258c.374.928.812 1.659 1.307 2.194.42.454.73 1.025.942 1.709zm-3.582-1.767c-1.357 1.533-1.68 2.899-1.607 4.79.013.352.013.34.013.448 0 2.528 2.783 5 6 5 3.216 0 6-2.473 6-5 0-2.542-.569-3.865-2.32-6.335l-.108-.154c-1.064-1.5-1.697-2.543-2.117-3.71-.685.82-.644 1.636-.238 3.197.75 2.873.451 4.448-2.132 6.313l-1.473 1.063-.11-1.813c-.07-1.159-.333-1.946-.737-2.383a7.886 7.886 0 01-1.082-1.515l-.089.099z"
      />
    </svg>
  );
}

export default IconFlame;

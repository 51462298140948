import PropTypes from "prop-types";
import cn from "classnames";
import { useRouter } from "next/router";
import { useHistoryContext } from "context/history-context";
import Link from "next/link";
import IconPrevious from "solar/icons/IconPrevious";
import styles from "components/shared/stays/NavbarSmallBack.module.scss";

export default function NavbarSmallBack({
  parentName,
  parentPath,
  isListing = false,
}) {
  const router = useRouter();
  const historyContext = useHistoryContext();

  return (
    <div>
      {historyContext.previousRoute ? (
        <button
          type="button"
          onClick={() => router.back()}
          className={cn(styles.button, isListing && styles.listing)}
        >
          <IconPrevious />
        </button>
      ) : (
        <Link
          prefetch={false}
          href={parentPath}
          className={cn(styles.link, isListing && styles.listing)}
        >
          <IconPrevious />
          {!isListing && <span>{parentName}</span>}
        </Link>
      )}
    </div>
  );
}

NavbarSmallBack.propTypes = {
  parentName: PropTypes.string.isRequired,
  parentPath: PropTypes.string.isRequired,
  isListing: PropTypes.bool,
};

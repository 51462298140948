import PropTypes from "prop-types";
import styles from "components/auth/FormGroup.module.scss";

export default function FormGroup({ children }) {
  return <div className={styles.component}>{children}</div>;
}

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

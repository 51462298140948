import moment from "moment";
import { formatMaxPrice } from "helpers/formatting";

export function isBooleanKey(key) {
  return key.startsWith("is_");
}

export function isNumericKey(key) {
  return key.startsWith("lowest_price_");
}

export function isNumericRangeComparisonKey(key) {
  return key.startsWith("lowest_price_");
}

export function numericRangeComparisonText(val) {
  return `$${val[0]} to $${formatMaxPrice(val[1])}`;
}

export function seasonFilterText(months, waveTypes) {
  return `${months.join(", ")} in ${waveTypes.join(", ")} season`;
}

export function monthContainsPeakOrShoulder(monthsArr, months, waveTypes) {
  const newWaveTypesMapping = {
    Peak: "H",
    Shoulder: "M",
    Off: "L",
  };

  const newWaveTypes = waveTypes.map((wave) => newWaveTypesMapping[wave]);
  const monthIndexes = months.map((month) => moment.months().indexOf(month));

  if (monthIndexes && monthsArr) {
    for (let i = 0; i < monthIndexes.length; i += 1) {
      if (newWaveTypes.includes(monthsArr[monthIndexes[i]])) {
        return true;
      }
    }
  }
  return false;
}

export function searchKeys() {
  // array of all query params used in search
  return ["q", "lat", "lng", "bbox", "place"];
}

export function maxPricePerTraveler() {
  return 350;
}

export function applyFilter(name, val, setFilters) {
  // only add values that are not empty arrays
  if (val && val instanceof Array && val.length > 0) {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: val }));
  } else {
    setFilters((current) => {
      // create copy of filters object
      const filtersCopy = { ...current };
      // remove empty key from object
      delete filtersCopy[name];
      return filtersCopy;
    });
  }
}

/* eslint-disable camelcase, no-undef */
export function setHeapUser({ id, email, first_name, last_name }) {
  heap.identify(id);
  heap.addUserProperties({
    Email: email,
    "First Name": first_name,
    "Last Name": last_name,
  });
}

export function logout() {
  heap.resetIdentity();
}

export function track(name, properties = {}) {
  heap.track(name, properties);
  // heap.track('Purchase', {dollars: 50, item: 'Furby'});
}

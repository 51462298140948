import * as React from "react";

function IconPrevious() {
  return (
    <svg
      width={11}
      height={18}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.414 9l7.293-7.293L9.293.293.586 9l8.707 8.707 1.414-1.414L3.414 9z"
      />
    </svg>
  );
}

export default IconPrevious;

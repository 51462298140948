import PropTypes from "prop-types";
import styles from "components/auth/Form.module.scss";

export default function Form({ children }) {
  return <div className={styles.component}>{children}</div>;
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
};

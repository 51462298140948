import PropTypes from "prop-types";
import { setUser } from "lib/analytics";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { loginUser } from "lib/api";
import { useRouter } from "next/router";
import { useAuthContext } from "context/auth-context";
import TextInputWithLabel from "solar/components/TextInputWithLabel";
import Button from "solar/components/Button";
import Form from "components/auth/Form";
import FormSection from "components/auth/FormSection";
import FormGroup from "components/auth/FormGroup";
import FormNote from "solar/components/FormNote";
import FormButton from "components/auth/FormButton";
import Alert from "solar/components/Alert";

export default function LoginForm({ handleLogin }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const router = useRouter();
  const authContext = useAuthContext();
  const schema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function submitForm(data) {
    const response = await loginUser(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      authContext.setUserData(response.access);
      setUser(response);
      const redirectURL = router.query.redirect_url;
      if (redirectURL) {
        router.push(redirectURL);
      } else if (handleLogin) {
        handleLogin();
      } else {
        router.push("/");
      }
    }
  }

  return (
    <>
      {errorMessage && (
        <Alert type="warning" title="Let's try that again">
          {errorMessage}
        </Alert>
      )}
      <Form>
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <FormSection>
            <FormGroup>
              <TextInputWithLabel
                id="email"
                name="email"
                type="email"
                register={register}
                hasError={errors.email != null}
                labelText="Email"
                block
              />
              {errors.email && (
                <FormNote hasError>{errors.email.message}</FormNote>
              )}
            </FormGroup>
            <FormGroup>
              <TextInputWithLabel
                id="password"
                name="password"
                type="password"
                register={register}
                hasError={errors.password != null}
                labelText="Password"
                block
              />
              {errors.password && (
                <FormNote hasError>{errors.password.message}</FormNote>
              )}
            </FormGroup>
          </FormSection>
          <FormSection>
            <FormButton>
              <Button size={2} type="submit" isSubmitting={isSubmitting} block>
                Log in
              </Button>
            </FormButton>
          </FormSection>
        </form>
      </Form>
    </>
  );
}

LoginForm.propTypes = {
  handleLogin: PropTypes.func,
};

LoginForm.defaultProps = {
  handleLogin: null,
};

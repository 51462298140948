import * as React from "react";

function IconDirection45() {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3L1 11.571l8.235 3.194L12.43 23 21 3zm-8.479 14.707l-1.74-4.488-4.488-1.74 10.9-4.671-4.672 10.899z"
      />
    </svg>
  );
}

export default IconDirection45;

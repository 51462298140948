import PropTypes from "prop-types";
import Link from "next/link";
import IconArrowLeftSm from "solar/icons/IconArrowLeftSm";
import Container from "solar/components/Container";
import styles from "components/shared/stays/BannerAdventures.module.scss";

export default function BannerAdventures({ wide = false, fluid = false }) {
  return (
    <div className={styles.component}>
      <Container wide={wide} fluid={fluid} className={styles.grid}>
        <IconArrowLeftSm />
        &nbsp;&nbsp;
        <Link prefetch={false} href="/adventures">
          Go to adventures
        </Link>
      </Container>
    </div>
  );
}

BannerAdventures.propTypes = {
  wide: PropTypes.bool,
  fluid: PropTypes.bool,
};

import * as React from "react";

function IconMapPin() {
  return (
    <svg
      width={24}
      height={24}
      fill="currenColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.328l.658-.575C18.188 17.923 21 13.707 21 10c0-5.25-4.097-9-9-9s-9 3.75-9 9c0 3.707 2.811 7.924 8.342 12.753l.658.575zm0-2.665C7.307 16.433 5 12.85 5 10c0-4.103 3.164-7 7-7s7 2.897 7 7c0 2.85-2.307 6.433-7 10.663zM12 5a5 5 0 110 10 5 5 0 010-10zm-3 5a3 3 0 116 0 3 3 0 01-6 0z"
      />
    </svg>
  );
}

export default IconMapPin;

import PropTypes from "prop-types";
import cn from "classnames";
import Container from "solar/components/Container";
import Search from "components/shared/stays/Search";
import NavbarLogo from "components/shared/stays/NavbarLogo";
import NavbarBigMenu from "components/shared/stays/NavbarBigMenu";
import NavbarBigLinks from "components/shared/stays/NavbarBigLinks";
import styles from "components/shared/stays/NavbarBig.module.scss";

export default function NavbarBig({
  bigPrimary,
  bigSecondary,
  bigTertiary,
  bigFluid,
  bigWide,
  links,
}) {
  return (
    <div className={cn(styles.component, bigFluid && styles.bigFluid)}>
      <Container fluid={bigFluid} wide={bigWide} className={styles.grid}>
        <div className={styles.primary}>
          {bigPrimary === "logo" && <NavbarLogo />}
          {bigPrimary === "links" && <NavbarBigLinks links={links} />}
        </div>
        <div className={styles.secondary}>
          {bigSecondary === "links" && <NavbarBigLinks links={links} />}
          {bigSecondary === "search" && <Search />}
          {bigSecondary === "logo" && <NavbarLogo />}
        </div>
        <div className={styles.tertiary}>
          {bigTertiary === "links" && (
            <NavbarBigLinks links={links} orientation="right" />
          )}
          {bigTertiary === "menu" && <NavbarBigMenu />}
          {bigTertiary === "links-and-menu" && (
            <>
              <NavbarBigLinks links={links} orientation="right" />
              <NavbarBigMenu />
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

NavbarBig.propTypes = {
  bigPrimary: PropTypes.oneOf(["links", "logo", "none", ""]).isRequired,
  bigSecondary: PropTypes.oneOf(["links", "search", "logo", "none", ""])
    .isRequired,
  bigTertiary: PropTypes.oneOf(["links", "menu", "links-and-menu", "none", ""])
    .isRequired,
  bigFluid: PropTypes.bool.isRequired,
  bigWide: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      newTab: PropTypes.bool,
      inMenu: PropTypes.bool,
      highlighted: PropTypes.bool,
    })
  ),
};

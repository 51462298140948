import * as React from "react";

function IconInfo() {
  return (
    <svg width={22} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11z"
        fill="#519BF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.004 13h.999v2h-4v-2h1v-2h-1V9h3v4zM11 8a1 1 0 100-2 1 1 0 000 2z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconInfo;

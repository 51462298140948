import React from "react";
import useRouteUrlHistory from "helpers/hooks/useRouterUrlHistory";

const HistoryContext = React.createContext();

function HistoryProvider(props) {
  const { previousRoute } = useRouteUrlHistory();

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    previousRoute,
  };

  return <HistoryContext.Provider value={value} {...props} />; // eslint-disable-line react/jsx-props-no-spreading
}

function useHistoryContext() {
  const context = React.useContext(HistoryContext);
  if (context === undefined) {
    throw new Error(`useHistoryContext must be used within a HistoryProvider`);
  }
  return context;
}

export { HistoryProvider, useHistoryContext };

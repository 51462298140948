import { Deserializer } from "jsonapi-serializer";
import { clientAxiosInstance, serverAxiosInstance } from "./axiosInstances";

export async function callAPI(
  url,
  {
    method = "get",
    data = undefined,
    cookies = undefined,
    redirectOnLogout = true,
  } = {}
) {
  try {
    const requestObj = { method, data, url };
    if (method === "get" && data !== undefined) requestObj.params = data;
    if (!url.startsWith("/api"))
      requestObj.baseURL = process.env.NEXT_PUBLIC_API_URL;
    const response = cookies
      ? await serverAxiosInstance(cookies).request(requestObj)
      : await clientAxiosInstance(redirectOnLogout).request(requestObj);
    if (response.data === "") {
      // if POST then location is URL for newly created resource
      return response.headers.location !== undefined
        ? { location: response.headers.location }
        : "";
    }
    const deserializer = new Deserializer({
      // api keys are underscore_case anyway so
      // don't do any transformation on incoming keys
      keyForAttribute: (str) => str,
      // keyForAttribute: "underscore_case",
    });
    return await deserializer.deserialize(response.data);
  } catch (error) {
    // TODO: send error message to sentry
    // https://github.com/vercel/next.js/tree/canary/examples/with-sentry
    let errorMsg = error.message;
    if (error.response) {
      if (error.response.status === 500) errorMsg = "API Internal Server Error";
      else errorMsg = error.response.data.error;
    }
    return { errorMsg, errorStatus: error.response?.status };
  }
}

export function getCollections() {
  return callAPI("/collections");
}

export function getCollection(id) {
  return callAPI(`/collections/${id}`);
}

export function getTrips(lat = null, lng = null) {
  let url = "/trips";
  if (lat !== null && lng !== null) url += `?lat=${lat}&lng=${lng}`;
  return callAPI(url);
}

export function getTripsForSearch() {
  return callAPI("/trips/minimal_for_search");
}

export function getTrip(id) {
  return callAPI(`/trips/${id}`);
}

export function getOperators() {
  return callAPI("/operators");
}

export function getOperator(id) {
  return callAPI(`/operators/${id}`);
}

export function getDestinations() {
  return callAPI("/destinations");
}

export function getDestination(id) {
  return callAPI(`/destinations/${id}`);
}

export function registerUser(data) {
  return callAPI("/api/users", { method: "post", data });
}

export function updateUser(id, data) {
  return callAPI(`/users/${id}`, { method: "put", data });
}

export function loginUser(data) {
  return callAPI("/api/login", { method: "post", data });
}

export function changePassword(data) {
  return callAPI("/change_password", { method: "post", data });
}

export function newBooking(data) {
  return callAPI("/bookings/new", { method: "get", data });
}

export function createBooking(data) {
  return callAPI("/bookings", { method: "post", data });
}

export function getBooking(id) {
  return callAPI(`/bookings/${id}`);
}

// NOTE: called server side so this is not used anymore
export function getBookings() {
  return callAPI("/bookings");
}

export function getMessage(id) {
  return callAPI(`/messages/${id}`);
}

export function createMessage(data) {
  return callAPI("/messages", { method: "post", data });
}

export function getStripePaymentIntent(bookingID) {
  return callAPI(`/bookings/${bookingID}/stripe_payment_intent`);
}

export function getAdventureStripePaymentIntent(bookingID, amount, intent) {
  let str = `/adventure_bookings/${bookingID}/stripe_payment_intent?amount=${amount}`;
  if (intent !== null) str += `&payment_intent=${intent?.split("_secret_")[0]}`;
  return callAPI(str);
}

export function forgotPassword(data) {
  return callAPI("/forgot_password", { method: "post", data });
}

export function resetPassword(data) {
  return callAPI("/reset_password", { method: "post", data });
}

export function logoutUser() {
  return callAPI("/api/logout");
}

export function getVariants(productIDs, startDate, endDate) {
  return callAPI(
    `/variants?product_id=${productIDs.join(
      ","
    )}&start_date=${startDate}&end_date=${endDate}`
  );
}

export function applyPromoCode(bookingID, data) {
  return callAPI(
    `/bookings/${bookingID}/apply_promo_code_to_existing_booking`,
    { method: "post", data }
  );
}

export function applyAdventurePromoCode(bookingID, data) {
  return callAPI(`/adventure_bookings/${bookingID}/apply_promo_code`, {
    method: "post",
    data,
  });
}

export function checkPromoCodeIsValid(data) {
  return callAPI(`/promotions/check_promo_code_is_valid`, {
    method: "post",
    data,
  });
}

export function getTripScoutSpots(tripID) {
  return callAPI(`/trips/${tripID}/available_scout_spots`);
}

export function claimScoutSpot(tripID) {
  return callAPI(`/scout_spots`, { method: "post", data: { trip_id: tripID } });
}

export function createWishlist(data) {
  return callAPI("/wishlists", { method: "post", data });
}

export function updateWishlist(slug, data) {
  return callAPI(`/wishlists/${slug}`, { method: "put", data });
}

export function deleteWishlist(slug, data) {
  return callAPI(`/wishlists/${slug}`, { method: "delete", data });
}

export function getWishlists(redirectOnLogout = true) {
  return callAPI("/wishlists", { redirectOnLogout });
}

export function getWishlist(slug) {
  return callAPI(`/wishlists/${slug}`, {
    method: "get",
  });
}

export function wishlistAddTrip(slug, tripID) {
  return callAPI(`/wishlists/${slug}/add_trip`, {
    method: "post",
    data: { trip_id: tripID },
  });
}

export function wishlistRemoveTrip(slug, tripID) {
  return callAPI(`/wishlists/${slug}/remove_trip`, {
    method: "post",
    data: { trip_id: tripID },
  });
}

export function getSurfFeatureTags() {
  return callAPI("/surf_feature_tags");
}

export function getAccommodationFeatureTags() {
  return callAPI("/accommodation_feature_tags");
}

export function getCategoryTags() {
  return callAPI("/category_tags");
}

export function createAdventureRequest(data) {
  return callAPI("/adventure_requests", { method: "post", data });
}

export function getAdventureBooking(id) {
  return callAPI(`/adventure_bookings/${id}`);
}

export function updateAdventureBooking(id, data) {
  return callAPI(`/adventure_bookings/${id}`, { method: "put", data });
}

export function updateAdventureBookingPhoneNumber(id, number) {
  return callAPI(`/adventure_bookings/${id}/update_phone_number`, {
    method: "put",
    data: { phone_number: number },
  });
}

export function getInineraryAttributes() {
  return callAPI("/itinerary_attributes");
}

export function getAdventures() {
  return callAPI("/adventures");
}

export function getAdventure(id) {
  return callAPI(`/adventures/${id}`);
}

export function getEvents() {
  return callAPI("/events");
}

export function getEvent(id) {
  return callAPI(`/events/${id}`);
}

export function newEventBooking(data) {
  return callAPI("/event_bookings/new", { method: "get", data });
}

export function getEventStripePaymentIntent(amount, data, intent) {
  const {
    event_variant_id, // eslint-disable-line camelcase
    num_adults, // eslint-disable-line camelcase
    num_children, // eslint-disable-line camelcase
    phone_number, // eslint-disable-line camelcase
    individual_rooms, // eslint-disable-line camelcase
    promo_code, // eslint-disable-line camelcase
    email,
    first_name, // eslint-disable-line camelcase
    last_name, // eslint-disable-line camelcase
  } = data;
  let str = `/event_bookings/stripe_payment_intent?amount=${amount}&event_variant_id=${event_variant_id}&phone_number=${phone_number}&num_adults=${num_adults}&num_children=${num_children}&individual_rooms=${individual_rooms}&promo_code=${promo_code}&email=${email}&first_name=${first_name}&last_name=${last_name}`; // eslint-disable-line camelcase
  if (intent !== null) str += `&payment_intent=${intent?.split("_secret_")[0]}`;
  return callAPI(str);
}

export function getEventStripePaymentIntentForReservation(
  bookingID,
  amount,
  intent
) {
  let str = `/event_bookings/${bookingID}/stripe_payment_intent_for_reservation?amount=${amount}`;
  if (intent !== null) str += `&payment_intent=${intent?.split("_secret_")[0]}`;
  return callAPI(str);
}

export function getEventBooking(id) {
  return callAPI(`/event_bookings/${id}`);
}

export function getRecentEventBookingPurchase(email) {
  return callAPI(`/event_bookings/recent_purchase_by_email?email=${email}`);
}

import PropTypes from "prop-types";
import cn from "classnames";
import IconMapPin from "solar/icons/IconMapPin";
import styles from "components/shared/stays/SearchItem.module.scss";

export default function SearchItem({
  text,
  icon = null,
  highlighted = false,
  inputValue = null,
  hasDivider,
  city,
}) {
  const boldText = (str, term) => {
    const searchTermRegex = new RegExp(
      `(${term.toString().replace(/[.\\+*?[^\]$(){}=!<>|:-]/g, "\\$&")})`,
      "gi"
    );
    const substrings = str.split(searchTermRegex);
    const substringsWithIds = substrings.map((item, index) => ({
      term: item,
      idx: index,
    }));

    return (
      <div>
        {substringsWithIds.map((substring) =>
          substring.term.toLowerCase() === term.toString().toLowerCase() ? (
            <span key={substring.idx.toString()} className={styles.bold}>
              {substring.term}
            </span>
          ) : (
            substring.term
          )
        )}
      </div>
    );
  };

  return (
    <div
      className={cn(
        styles.component,
        highlighted && styles.highlighted,
        hasDivider && styles.divider
      )}
    >
      <div className={styles.link}>
        <div className={styles.icon}>{icon || <IconMapPin />}</div>
        <div className={styles.text}>
          {inputValue ? boldText(text, inputValue) : text}
          {city && (
            <div className={styles.city}>
              {inputValue ? boldText(city, inputValue) : city}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

SearchItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  highlighted: PropTypes.bool,
  inputValue: PropTypes.string,
  hasDivider: PropTypes.bool,
  city: PropTypes.string,
};

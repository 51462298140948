import * as React from "react";

function IconLightbulb() {
  return (
    <svg
      width={24}
      height={24}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15.915V18a4 4 0 01-8 0v-2.085c0-.195-.07-.408-.233-.689a6.665 6.665 0 00-.449-.652l-.07-.091c-.138-.18-.44-.574-.552-.725C5.566 12.232 5 10.87 5 9a7 7 0 0114 0c0 1.91-.574 3.325-1.694 4.848-.12.161-.419.55-.596.781l-.134.175a4.609 4.609 0 00-.459.7.938.938 0 00-.117.411zm-1.956-.476c.05-.276.146-.548.28-.82.158-.32.358-.625.653-1.017l.17-.22v-.001c.18-.234.447-.581.547-.718C16.583 11.455 17 10.428 17 9A5 5 0 007 9c0 1.379.407 2.357 1.304 3.568.096.13.368.485.515.676l.094.124c.252.332.43.589.583.852.238.41.397.808.465 1.222A3.872 3.872 0 0012 16c.744 0 1.42-.185 2.044-.56zM14 18v-.338c-.633.225-1.3.338-2 .338s-1.367-.113-2-.338V18a2 2 0 104 0z"
      />
    </svg>
  );
}

export default IconLightbulb;

import PropTypes from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import jsCookie from "js-cookie";
import { registerUser } from "lib/api";
import { useRouter } from "next/router";
import { useAuthContext } from "context/auth-context";
import { sendUserSignupEvent, setUser } from "lib/analytics";
import Alert from "solar/components/Alert";
import TextInputWithLabel from "solar/components/TextInputWithLabel";
import Button from "solar/components/Button";
import Form from "components/auth/Form";
import FormSection from "components/auth/FormSection";
import FormGroup from "components/auth/FormGroup";
import FormNote from "solar/components/FormNote";
import FormButton from "components/auth/FormButton";
import styles from "components/auth/SignupForm.module.scss";

export default function SignupForm({ handleSignup }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const router = useRouter();
  const authContext = useAuthContext();
  const schema = Yup.object()
    .shape({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string().required("Email is required").email("Invalid Email"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Minimum 8 characters"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function submitForm(data) {
    const utmSource = jsCookie.get("utm-source");
    if (utmSource !== undefined) {
      data.utm_source = utmSource; // eslint-disable-line no-param-reassign
    }
    const response = await registerUser(data);
    if (response.errorMsg) {
      setErrorMessage(response.errorMsg);
    } else {
      authContext.setUserData(response.access);
      setUser(response);
      sendUserSignupEvent();
      const redirectURL = router.query.redirect_url;
      if (redirectURL) {
        router.push(redirectURL);
      } else if (handleSignup) {
        handleSignup();
      } else {
        router.push("/");
      }
    }
  }

  // force form state to update for autofilled values
  const updateField = (name, val) => {
    setValue(name, val);
  };

  return (
    <>
      {errorMessage && (
        <Alert type="warning" title="Let's try that again">
          {errorMessage}
        </Alert>
      )}
      <Form>
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <FormSection>
            <FormGroup>
              <TextInputWithLabel
                id="first_name"
                name="first_name"
                type="text"
                register={register}
                labelText="First name"
                hasError={!!errors.first_name}
                block
                pairTop
                onChange={(e) => updateField("first_name", e.target.value)}
              />
              <TextInputWithLabel
                id="last_name"
                name="last_name"
                type="text"
                register={register}
                labelText="Last name"
                hasError={!!errors.last_name}
                block
                pairBottom
                onChange={(e) => updateField("last_name", e.target.value)}
              />
              {errors.first_name && (
                <FormNote hasError>{errors.first_name.message}</FormNote>
              )}
              <FormNote hasError={!!errors.last_name}>
                {errors.last_name?.message ||
                  "Your last name will only be used for bookings."}
              </FormNote>
            </FormGroup>
          </FormSection>
          <div className={styles.divider} />
          <FormSection>
            <FormGroup>
              <TextInputWithLabel
                id="email"
                name="email"
                type="email"
                register={register}
                hasError={!!errors.email}
                labelText="Email"
                block
                onChange={(e) => updateField("email", e.target.value)}
              />
              {errors.email && (
                <FormNote hasError>{errors.email.message}</FormNote>
              )}
            </FormGroup>
            <FormGroup>
              <TextInputWithLabel
                id="password"
                name="password"
                type="password"
                register={register}
                hasError={!!errors.password}
                labelText="Password"
                block
              />
              {errors.password && (
                <FormNote hasError>{errors.password.message}</FormNote>
              )}
            </FormGroup>
          </FormSection>
          <FormSection>
            <FormButton>
              <Button
                size={2}
                type="submit"
                isSubmitting={isSubmitting}
                block
                heap="signup"
              >
                Sign up
              </Button>
            </FormButton>
          </FormSection>
        </form>
      </Form>
    </>
  );
}

SignupForm.propTypes = {
  handleSignup: PropTypes.func,
};

SignupForm.defaultProps = {
  handleSignup: null,
};

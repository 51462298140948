import PropTypes from "prop-types";
import cn from "classnames";
import Link from "next/link";
import styles from "components/shared/stays/NavbarBigLink.module.scss";

export default function NavbarBigLink({
  href,
  text,
  newTab = true,
  inMenu = false,
  highlighted = false,
}) {
  return (
    <span className={cn(inMenu && styles.menu)}>
      {newTab ? (
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className={cn(styles.link, highlighted && styles.highlighted)}
        >
          {text}
        </a>
      ) : (
        <Link
          prefetch={false}
          href={href}
          className={cn(styles.link, highlighted && styles.highlighted)}
        >
          {text}
        </Link>
      )}
    </span>
  );
}

NavbarBigLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  inMenu: PropTypes.bool,
  highlighted: PropTypes.bool,
};

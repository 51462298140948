import PropTypes from "prop-types";
import { useState } from "react";
import axios from "axios";
import jsCookie from "js-cookie";
import { sendSubscribeEvent } from "lib/analytics";
import useUserAuth from "helpers/hooks/useUserAuth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Text from "components/shared/stays/Text";
import Button from "solar/components/Button";
import Modal from "components/shared/stays/Modal";
import ModalBody from "components/shared/stays/ModalBody";
import TextInput from "solar/components/TextInput";
import TextInputWithSubmit from "solar/components/TextInputWithSubmit";
import FormNote from "solar/components/FormNote";
import Alert from "solar/components/Alert";
import Title from "components/shared/stays/Title";
import styles from "components/shared/stays/OverlayPromo.module.scss";

export default function OverlayPromo({
  show,
  setShow,
  couponCode,
  amountOff,
  amountMin,
  introText,
  termsLink,
  priority,
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [subscribed, setSubscribed] = useState(false);
  const authenticated = useUserAuth();

  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Your email is required.")
      .email("A valid email is required."),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  function handleClose() {
    setShow(false);
  }

  async function submitForm(data) {
    try {
      const utmSource = jsCookie.get("utm-source");

      if (couponCode !== undefined) {
        data.subscribeCouponCode = couponCode; // eslint-disable-line no-param-reassign
      }

      if (amountOff !== undefined) {
        data.subscribeCouponAmountOff = amountOff; // eslint-disable-line no-param-reassign
      }

      if (amountMin !== undefined) {
        data.subscribeCouponAmountMin = amountMin; // eslint-disable-line no-param-reassign
      }

      if (utmSource !== undefined) {
        data.utmSource = utmSource; // eslint-disable-line no-param-reassign
      }

      data.subscribeFormLocation = "Overlay"; // eslint-disable-line no-param-reassign

      await axios.post("/api/subscribe", data);
      setSubscribed(true);
      sendSubscribeEvent("overlay");
    } catch (error) {
      setErrorMessage(error.response.data.errorMsg);
    }
  }

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      controlsOverlay
      className={styles.modal}
      priority={priority}
    >
      <div className={styles.grid}>
        <ModalBody className={styles.body}>
          {authenticated ? (
            <>
              <Text className={styles.text}>
                For ${amountOff} off your trip over ${amountMin}, add the coupon
                code below when paying.
              </Text>
              <TextInput
                id="coupon"
                type="text"
                name="coupon"
                defaultValue={couponCode}
                block
                className={styles.code}
              />
              <Button
                size={2}
                block
                className={styles.action}
                onClick={() => handleClose()}
              >
                Explore Thermal
              </Button>
              <div className={styles.footer}>
                <Text size={3}>&nbsp;</Text>
              </div>
            </>
          ) : (
            <div>
              {subscribed ? (
                <>
                  <Text className={styles.text}>
                    You&apos;re all set. A ${amountOff} coupon code is on its
                    way to your inbox now.
                  </Text>
                  <Button
                    size={2}
                    ghost
                    block
                    className={styles.action}
                    onClick={() => handleClose()}
                  >
                    Explore Thermal
                  </Button>
                  <div className={styles.footer}>
                    <Text size={3}>&nbsp;</Text>
                  </div>
                </>
              ) : (
                <>
                  <Title font="cushing" className={styles.title}>
                    Get ${amountOff} off
                  </Title>
                  <Text className={styles.text}>{introText}</Text>
                  {errorMessage && (
                    <Alert type="warning" title="Let's try that again">
                      {errorMessage}
                    </Alert>
                  )}
                  <form
                    onSubmit={handleSubmit(submitForm)}
                    noValidate
                    className={styles.form}
                  >
                    <TextInputWithSubmit
                      id="email"
                      type="email"
                      name="email"
                      register={register}
                      placeholder="Email address"
                      block
                      labelText="Email"
                      heap="subscribe"
                      variant="light"
                      className={styles.input}
                    />
                    {errors.email && (
                      <FormNote hasError>{errors.email.message}</FormNote>
                    )}
                  </form>
                  <div className={styles.footer}>
                    <Text size={3}>
                      {"The "}
                      <a
                        href={termsLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        details
                      </a>
                      {". We take your "}
                      <a href="/privacy" target="_blank">
                        privacy
                      </a>
                      {" seriously."}
                    </Text>
                  </div>
                </>
              )}
            </div>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
}

OverlayPromo.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  couponCode: PropTypes.string,
  amountOff: PropTypes.number,
  amountMin: PropTypes.number,
  introText: PropTypes.string,
  termsLink: PropTypes.string,
  priority: PropTypes.string,
};

import { useState, useEffect } from "react";
import jsCookie from "js-cookie";
import { getAccessToken } from "helpers/userAuth";
import { useRouter } from "next/router";
import queryString from "query-string";
import OverlayPromo from "components/shared/stays/OverlayPromo";

export default function Overlays() {
  const promos = {
    get100: {
      delay: 15000,
      couponCode: "100NEW",
      amountOff: 100,
      amountMin: 500,
      overlayIntroText:
        "Subscribe to our newsletter and get $100 off your first surf trip.",
      messageText: "Get $100 off your first trip",
      termsLink:
        "https://www.notion.so/withthermal/Promo-Code-Terms-and-Conditions-f7611b373d124315858a94c1bd52de81",
    },
    buell200: {
      delay: 100,
      couponCode: "BUELL200",
      amountOff: 200,
      amountMin: 750,
      overlayIntroText:
        "Sign up for Thermal's newsletter and get a sweet discount to take your Buell wetsuit somewhere nice.",
      messageText: "Buell + Thermal: save $200",
      termsLink:
        "https://withthermal.notion.site/Partner-promo-terms-and-conditions-565204142b74498cabee09f5d3e19b45",
    },
    awesome200: {
      delay: 100,
      couponCode: "AWESOME200",
      amountOff: 200,
      amountMin: 750,
      overlayIntroText:
        "Sign up for Thermal's newsletter and get a sweet discount to someplace you find on Awesome Maps.",
      messageText: "Awesome Maps + Thermal: save $200",
      termsLink:
        "https://withthermal.notion.site/Partner-promo-terms-and-conditions-565204142b74498cabee09f5d3e19b45",
    },
    goodwave200: {
      delay: 100,
      couponCode: "GOODWAVE200",
      amountOff: 200,
      amountMin: 750,
      overlayIntroText:
        "Sign up for Thermal's newsletter and get a sweet discount on your next trip.",
      messageText: "Good Wave + Thermal: save $200",
      termsLink:
        "https://withthermal.notion.site/Partner-promo-terms-and-conditions-565204142b74498cabee09f5d3e19b45",
    },
    bighearts200: {
      delay: 100,
      couponCode: "BIGHEARTS200",
      amountOff: 200,
      amountMin: 750,
      overlayIntroText:
        "Sign up for Thermal's newsletter and get a sweet discount on your next trip.",
      messageText: "Big Hearts Tiny Spaces + Thermal: save $200",
      termsLink:
        "https://withthermal.notion.site/Partner-promo-terms-and-conditions-565204142b74498cabee09f5d3e19b45",
    },
    flylow200: {
      delay: 100,
      couponCode: "FLYLOW200",
      amountOff: 200,
      amountMin: 750,
      overlayIntroText:
        "Sign up for Thermal's newsletter and get a sweet discount on your next trip.",
      messageText: "Flylow + Thermal: save $200",
      termsLink:
        "https://withthermal.notion.site/Partner-promo-terms-and-conditions-565204142b74498cabee09f5d3e19b45",
    },
    rumpl200: {
      delay: 100,
      couponCode: "RUMPL200",
      amountOff: 200,
      amountMin: 750,
      overlayIntroText:
        "Sign up for Thermal's newsletter and get a sweet discount on your next trip.",
      messageText: "Rumpl + Thermal: save $200",
      termsLink:
        "https://withthermal.notion.site/Partner-promo-terms-and-conditions-565204142b74498cabee09f5d3e19b45",
    },
    ofonesea200: {
      delay: 100,
      couponCode: "OFONESEA200",
      amountOff: 200,
      amountMin: 750,
      overlayIntroText:
        "Sign up for Thermal's newsletter and get a sweet discount on your next trip.",
      messageText: "Of One Sea + Thermal: save $200",
      termsLink:
        "https://withthermal.notion.site/Partner-promo-terms-and-conditions-565204142b74498cabee09f5d3e19b45",
    },
    surfstrength200: {
      delay: 100,
      couponCode: "SURFSTRENGTH200",
      amountOff: 200,
      amountMin: 750,
      overlayIntroText:
        "Sign up for Thermal's newsletter and get a sweet discount on your next trip.",
      messageText: "Surf Strength + Thermal: save $200",
      termsLink:
        "https://withthermal.notion.site/Partner-promo-terms-and-conditions-565204142b74498cabee09f5d3e19b45",
    },
  };

  const hasToken = getAccessToken();
  const router = useRouter();
  const { query } = queryString.parseUrl(router.asPath);
  const [cookiesChecked, setCookiesChecked] = useState(false);

  const defaultActive = false; // enable/disable the use of a default promo
  const defaultPromoName = "get100";
  const defaultPromo = promos[defaultPromoName];
  const [state, setState] = useState({
    queryValue: null,
    cookiedName: null,
    cookiedSeen: null,
    promo: null,
  });

  const [showOverlayPromo, setShowOverlayPromo] = useState(false);

  function populatePromo() {
    const queryValue = query.promo;
    const initialCookiedName = jsCookie.get("overlayName");
    const updatedCookiedName =
      queryValue || initialCookiedName || defaultPromoName;
    const betterOffer =
      promos[updatedCookiedName]?.amountOff >
      promos[initialCookiedName]?.amountOff;
    const cookiedSeen =
      betterOffer || queryValue ? false : jsCookie.get("overlaySeen");

    setState({
      queryValue,
      initialCookiedName,
      updatedCookiedName,
      cookiedSeen,
      promo: promos[updatedCookiedName.toLowerCase()],
    });
    setCookiesChecked(true);
  }

  function showOverlay() {
    if (!hasToken && cookiesChecked && !state.cookiedSeen) {
      setShowOverlayPromo(true);
      jsCookie.set("overlayName", state.updatedCookiedName, { expires: 30 });
      jsCookie.set("overlaySeen", true, { expires: 30 });
    }
  }

  useEffect(() => {
    if (query.promo || defaultActive) {
      populatePromo();
      setTimeout(() => showOverlay(), state.promo?.delay);
    }

    // rt: set so that it only re-renders when the cookies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesChecked]);

  return (
    <OverlayPromo
      show={showOverlayPromo}
      setShow={setShowOverlayPromo}
      couponCode={state.promo?.couponCode || defaultPromo.couponCode}
      amountOff={state.promo?.amountOff || defaultPromo.amountOff}
      amountMin={state.promo?.amountMin || defaultPromo.amountMin}
      introText={state.promo?.overlayIntroText || defaultPromo.overlayIntroText}
      termsLink={state.promo?.termsLink || defaultPromo.termsLink}
    />
  );
}

import * as React from "react";

function IconWhatsAppColor() {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#prefix__clip0_1007_7)"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path
          d="M.596 23.404L2.2 17.547a11.279 11.279 0 01-1.51-5.65C.693 5.665 5.764.595 11.997.595a11.235 11.235 0 017.997 3.315 11.234 11.234 0 013.308 7.996c-.002 6.232-5.074 11.302-11.305 11.302h-.005c-1.892 0-3.752-.475-5.403-1.376L.596 23.404z"
          fill="#fff"
        />
        <path
          d="M.596 23.702a.297.297 0 01-.287-.376l1.572-5.738a11.6 11.6 0 01-1.487-5.69C.396 5.5 5.6.298 11.997.298A11.532 11.532 0 0120.205 3.7a11.53 11.53 0 013.396 8.206c-.003 6.396-5.208 11.6-11.604 11.6-1.899 0-3.777-.47-5.445-1.356l-5.88 1.542a.27.27 0 01-.076.01z"
          fill="#fff"
        />
        <path
          d="M11.997.595a11.235 11.235 0 017.997 3.315 11.234 11.234 0 013.308 7.996c-.002 6.232-5.074 11.302-11.305 11.302h-.005c-1.892 0-3.752-.475-5.403-1.376L.596 23.404 2.2 17.547a11.279 11.279 0 01-1.51-5.65C.693 5.665 5.764.595 11.997.595zm0-.595C5.437 0 .097 5.337.095 11.897c0 2.005.505 3.98 1.465 5.73l-1.539 5.62a.594.594 0 00.726.733l5.768-1.512a11.925 11.925 0 005.477 1.336c6.564 0 11.903-5.337 11.906-11.897a11.824 11.824 0 00-3.482-8.418A11.831 11.831 0 0011.996 0z"
          fill="#CFD8DC"
        />
        <path
          d="M18.644 5.26A9.333 9.333 0 0012 2.504c-5.183 0-9.398 4.213-9.4 9.393-.001 1.775.496 3.503 1.436 5l.224.355-.95 3.466 3.557-.933.344.204a9.379 9.379 0 004.783 1.31h.003c5.18 0 9.395-4.215 9.397-9.395 0-2.51-.975-4.87-2.75-6.646z"
          fill="#40C351"
        />
        <path
          d="M9.17 7.172c-.21-.47-.434-.48-.635-.488-.165-.007-.354-.006-.542-.006-.188 0-.494.07-.753.353s-.989.966-.989 2.356 1.012 2.733 1.153 2.921c.142.189 1.955 3.132 4.826 4.265 2.386.94 2.872.753 3.39.706.518-.047 1.672-.683 1.907-1.342.235-.66.235-1.225.165-1.343-.071-.118-.26-.188-.541-.33-.283-.142-1.672-.825-1.931-.919-.259-.094-.447-.14-.636.142-.188.282-.73.919-.894 1.107-.165.189-.33.213-.612.07-.283-.141-1.193-.439-2.272-1.401-.84-.748-1.407-1.673-1.572-1.956-.165-.282-.018-.435.124-.577.127-.126.282-.33.424-.494.141-.165.188-.283.282-.471.095-.19.047-.354-.023-.495-.07-.142-.62-1.539-.87-2.098z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1007_7">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconWhatsAppColor;
